import classNames from 'classnames';
import { flexRender, ColumnDef, Table as TanstackTable } from '@tanstack/react-table';
import { Settings, ViewerBoardItem } from './types';

type TableProps = {
  settings: Settings;
  columns: ColumnDef<ViewerBoardItem, string>[];
  table: TanstackTable<ViewerBoardItem>;
};

export function Table({ table, settings }: TableProps) {
  return (
    <div className={`table content-alignment-${settings.textAlignment}`}>
      <table>
        {settings.tableShowHeader ? (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div className="column_header" onClick={header.column.getToggleSortingHandler()}>
                      <span className="label">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </span>
                      <span className="icon">
                        <i
                          className={classNames('fa-solid', {
                            'fa-sort': header.column.getIsSorted() === false,
                            'fa-caret-up': header.column.getIsSorted() === 'desc',
                            'fa-caret-down': header.column.getIsSorted() === 'asc',
                          })}
                        ></i>
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        ) : null}
        <tbody>
          {table.getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={200}>
                <div style={{ padding: '20px', textAlign: 'center' }}>No results found</div>
              </td>
            </tr>
          ) : null}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
