import * as _ from 'lodash';
import { Settings, ViewerBoardInfo } from '@gorilla/widgets-viewer/app/viewer/types';

type FieldMatch = {
  id: string;
  type: string;
};

function fieldStructureMatches(boardInfo: ViewerBoardInfo, matchAgainst: FieldMatch[]) {
  const fields1 = boardInfo.fields.map((field) => `${field.id}___${field.type}`);
  const fields2 = matchAgainst.map((field) => `${field.id}___${field.type}`);

  const isSubset = _.difference(fields1, fields2).length === 0;
  const countAdditionalFields = _.difference(fields2, fields1).length;

  // TODO: allow additional fields?
  return isSubset && countAdditionalFields === 0;
}

type Demo = {
  name: string;
  requiredFields: FieldMatch[];
  settings: Settings;
};

const DEMOS: Demo[] = [
  {
    name: 'Feature Requests',
    requiredFields: [
      {
        id: 'name',
        type: 'name',
      },
      {
        id: '__group__',
        type: 'group',
      },
      {
        id: 'person',
        type: 'people',
      },
      {
        id: 'status',
        type: 'status',
      },
      {
        id: 'text3',
        type: 'text',
      },
      {
        id: 'long_text',
        type: 'long_text',
      },
      {
        id: 'date4',
        type: 'date',
      },
      {
        id: 'text',
        type: 'text',
      },
      {
        id: 'text7',
        type: 'text',
      },
      {
        id: 'email',
        type: 'email',
      },
      {
        id: 'files',
        type: 'file',
      },
    ],
    settings: {
      mode: 'table',
      search: true,
      fontSize: 14,
      textAlignment: 'left',
      gridMode: 'grid',
      fontFamily: 'Arial',
      sortColumn: '__group__',
      bodyPadding: 'medium',
      borderColor: '#D0D4E4',
      gridItemGap: 'medium',
      primaryColor: '#314182',
      sortDirection: 'asc',
      columnSettings: {
        name: { hidden: false, showLabel: false },
        text: { hidden: true },
        check: { labelMode: 'inline', showLabel: true },
        date4: { hidden: true },
        email: { hidden: true },
        files: { labelMode: 'above', showLabel: false, filesDisplayMode: 'thumbs' },
        text3: { labelMode: 'inline', showLabel: true },
        text7: { hidden: true },
        person: { hidden: true },
        status: { hidden: true },
        __group__: { hidden: false },
        long_text: { hidden: false },
      },
      secondaryColor: '#323338',
      backgroundColor: '#314182',
      gridItemPadding: 'medium',
      tableShowHeader: false,
      tableCellPadding: 'large',
      tableBorderRadius: 'none',
      gridItemImageColumn: null,
      gridItemBorderRadius: 'medium',
      gridItemHeadlineColumn: 'name',
      gridItemImageColumnFormat: null,
    },
  },
  {
    name: 'Reviews',
    requiredFields: [
      {
        id: 'name',
        type: 'name',
      },
      {
        id: '__group__',
        type: 'group',
      },
      {
        id: 'status',
        type: 'status',
      },
      {
        id: 'text_1',
        type: 'text',
      },
      {
        id: 'rating',
        type: 'rating',
      },
      {
        id: 'text6',
        type: 'long_text',
      },
      {
        id: 'date4',
        type: 'date',
      },
      {
        id: 'files',
        type: 'file',
      },
    ],
    settings: {
      mode: 'grid',
      search: false,
      fontSize: 14,
      textAlignment: 'left',
      gridMode: 'masonry',
      fontFamily: 'Helvetica',
      sortColumn: 'rating',
      bodyPadding: 'medium',
      borderColor: '#00D87E',
      gridItemGap: 'medium',
      primaryColor: '#323338',
      sortDirection: 'desc',
      columnSettings: {
        name: { hidden: true },
        date4: { hidden: true },
        files: { showLabel: false, filesDisplayMode: 'thumbs' },
        text6: { hidden: false },
        status: { hidden: true },
        text_1: { hidden: false },
        text_10: { hidden: true },
        __group__: { hidden: true },
      },
      secondaryColor: '#00D87E',
      backgroundColor: '#00D87E',
      gridItemPadding: 'large',
      tableShowHeader: true,
      tableCellPadding: 'medium',
      tableBorderRadius: 'small',
      gridItemImageColumn: null,
      gridItemBorderRadius: 'small',
      gridItemHeadlineColumn: 'name',
      gridItemImageColumnFormat: '1:AUTO_LARGE',
    },
  },
  {
    name: 'Real Estate',
    requiredFields: [
      {
        id: 'name',
        type: 'name',
      },
      {
        id: '__group__',
        type: 'group',
      },
      {
        id: 'person',
        type: 'people',
      },
      {
        id: 'type',
        type: 'status',
      },
      {
        id: 'numbers',
        type: 'numbers',
      },
      {
        id: 'numbers6',
        type: 'numbers',
      },
      {
        id: 'dropdown_1',
        type: 'dropdown',
      },
      {
        id: 'bedrooms',
        type: 'dropdown',
      },
      {
        id: 'text7',
        type: 'long_text',
      },
      {
        id: 'location',
        type: 'location',
      },
      {
        id: 'files',
        type: 'file',
      },
    ],
    settings: {
      mode: 'grid',
      search: false,
      fontSize: 14,
      textAlignment: 'left',
      gridMode: 'grid',
      fontFamily: 'Arial',
      sortColumn: 'name',
      bodyPadding: 'medium',
      borderColor: '#5D6586',
      gridItemGap: 'medium',
      primaryColor: '#323338',
      sortDirection: 'asc',
      columnSettings: {
        name: { hidden: true },
        type: { hidden: false },
        files: { hidden: true },
        person: { hidden: true },
        numbers: { labelMode: 'inline', showLabel: true },
        bedrooms: { labelMode: 'inline', showLabel: true },
        numbers6: { labelMode: 'inline', showLabel: true },
        __group__: { hidden: true },
        bedrooms1: { hidden: true },
        dropdown_1: { labelMode: 'inline', showLabel: true },
      },
      secondaryColor: '#5D6586',
      backgroundColor: '#5D6586',
      gridItemPadding: 'large',
      tableShowHeader: true,
      tableCellPadding: 'medium',
      tableBorderRadius: 'small',
      gridItemImageColumn: 'files',
      gridItemBorderRadius: 'medium',
      gridItemHeadlineColumn: 'name',
      gridItemImageColumnFormat: '4:3_LARGE',
    },
  },
  {
    name: 'Press Releases',
    requiredFields: [
      {
        id: 'name',
        type: 'name',
      },
      {
        id: '__group__',
        type: 'group',
      },
      {
        id: 'date4',
        type: 'date',
      },
      {
        id: 'long_text',
        type: 'long_text',
      },
      {
        id: 'person',
        type: 'people',
      },
      {
        id: 'status',
        type: 'status',
      },
      {
        id: 'text',
        type: 'text',
      },
      {
        id: 'files',
        type: 'file',
      },
    ],
    settings: {
      mode: 'table',
      search: false,
      fontSize: 14,
      textAlignment: 'left',
      gridMode: 'grid',
      fontFamily: 'Arial',
      sortColumn: 'date4',
      bodyPadding: 'small',
      borderColor: '#d0d3e0',
      gridItemGap: 'large',
      primaryColor: '#767c99',
      sortDirection: 'desc',
      columnSettings: {
        name: { hidden: false },
        text: { hidden: false },
        files: { showLabel: false, filesDisplayMode: 'list' },
        person: { hidden: true },
        status: { hidden: true },
        __group__: { hidden: true },
        long_text: { hidden: true },
      },
      secondaryColor: '#8389A7',
      backgroundColor: '#FFFFFF',
      gridItemPadding: 'small',
      tableShowHeader: true,
      tableCellPadding: 'medium',
      tableBorderRadius: 'small',
      gridItemImageColumn: null,
      gridItemBorderRadius: 'none',
      gridItemHeadlineColumn: 'name',
      gridItemImageColumnFormat: null,
    },
  },
  {
    name: 'Team Board',
    requiredFields: [
      {
        id: 'name',
        type: 'name',
      },
      {
        id: '__group__',
        type: 'group',
      },
      {
        id: 'person',
        type: 'people',
      },
      {
        id: 'position3',
        type: 'text',
      },
      {
        id: 'label',
        type: 'status',
      },
      {
        id: 'email',
        type: 'email',
      },
      {
        id: 'phone',
        type: 'phone',
      },
      {
        id: 'link',
        type: 'link',
      },
      {
        id: 'files',
        type: 'file',
      },
    ],
    settings: {
      mode: 'grid',
      search: false,
      fontSize: 14,
      textAlignment: 'left',
      gridMode: 'grid',
      fontFamily: 'Arial',
      sortColumn: 'name',
      bodyPadding: 'small',
      borderColor: '#eff1f9',
      gridItemGap: 'medium',
      primaryColor: '#323338',
      sortDirection: 'asc',
      columnSettings: { name: { hidden: true }, files: { hidden: true }, label: { hidden: true }, __group__: { hidden: true } },
      secondaryColor: '#868995',
      backgroundColor: '#eff1f9',
      gridItemPadding: 'large',
      tableShowHeader: true,
      tableCellPadding: 'medium',
      tableBorderRadius: 'small',
      gridItemImageColumn: 'files',
      gridItemBorderRadius: 'medium',
      gridItemHeadlineColumn: 'name',
      gridItemImageColumnFormat: '1:AUTO_LARGE',
    },
  },
  {
    name: 'Showrooms',
    requiredFields: [
      {
        id: 'name',
        type: 'name',
      },
      {
        id: '__group__',
        type: 'group',
      },
      {
        id: 'person',
        type: 'people',
      },
      {
        id: 'text',
        type: 'text',
      },
      {
        id: 'text2',
        type: 'text',
      },
      {
        id: 'text23',
        type: 'text',
      },
      {
        id: 'link',
        type: 'link',
      },
      {
        id: 'email',
        type: 'email',
      },
      {
        id: 'location',
        type: 'location',
      },
    ],
    settings: {
      mode: 'grid',
      search: true,
      fontSize: 14,
      textAlignment: 'left',
      gridMode: 'grid',
      fontFamily: 'Helvetica',
      sortColumn: 'name',
      bodyPadding: 'large',
      borderColor: '#FFFFFF',
      gridItemGap: 'small',
      primaryColor: '#323338',
      sortDirection: 'asc',
      columnSettings: {
        name: { hidden: true },
        text: { labelMode: 'above', showLabel: true },
        email: { hidden: true },
        text2: { showLabel: true },
        person: { hidden: true },
        status: { hidden: true },
        text23: { hidden: false, showLabel: true },
        status0: { hidden: true },
        __group__: { hidden: false },
      },
      secondaryColor: '#edbcbc',
      backgroundColor: '#F9E2E2',
      gridItemPadding: 'large',
      tableShowHeader: true,
      tableCellPadding: 'medium',
      tableBorderRadius: 'small',
      gridItemImageColumn: null,
      gridItemBorderRadius: 'small',
      gridItemHeadlineColumn: 'name',
      gridItemImageColumnFormat: null,
    },
  },
];

export function getDemoSettings(boardInfo: ViewerBoardInfo) {
  const demo = DEMOS.find((demo) => fieldStructureMatches(boardInfo, demo.requiredFields));

  /*
  console.log(
    'board fields',
    boardInfo.fields.map((field) => ({ id: field.id, type: field.type })),
  );
  */

  if (!demo) {
    return null;
  }

  return demo.settings;
}
