import { Flex, Heading, Toggle, Box } from 'monday-ui-react-core';

type ToggleSettingProps = {
  title: string;
  description?: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

export function ToggleSetting({ title, value, onChange }: ToggleSettingProps) {
  return (
    <Flex direction={Flex.directions?.ROW} style={{ width: '100%' }}>
      <Heading type={Heading.types?.h5 as any} value={title} />
      <Box marginStart={Box.marginStarts?.AUTO}>
        {/* <Tooltip content={description} position={Tooltip.positions.RIGHT}> */}
        <Toggle
          className="Toggle"
          areLabelsHidden
          isSelected={value}
          onChange={() => {
            onChange(!value);
          }}
        />
        {/* </Tooltip> */}
      </Box>
    </Flex>
  );
}
