import { Flex, Button, Box, Modal, ModalHeader, ModalContent } from 'monday-ui-react-core';
// @ts-ignore-next-line
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
// @ts-ignore-next-line
import CompletedIcon from 'monday-ui-react-core/dist/icons/Completed';
// @ts-ignore-next-line
import FileIcon from 'monday-ui-react-core/dist/icons/File';

type DeleteWidgetModalProps = {
  show: boolean;
  isUnpublishing: boolean;
  onClose: (doDelete: boolean) => void;
};

export function DeleteWidgetModal({ show, onClose, isUnpublishing }: DeleteWidgetModalProps) {
  return (
    <Modal
      contentSpacing
      alertDialog={true}
      data-testid="deleteWidgetModal"
      width={Modal.width?.DEFAULT}
      show={show}
      onClose={() => {
        onClose(false);
      }}
    >
      <ModalHeader data-testid="publishModalHeader" title="Unpublish widget" description="" closeButtonAriaLabel="" />
      <ModalContent>
        <Box marginBottom={Box.marginBottoms?.LARGE}>
          <Flex gap={8} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
            By unpublishing this widget, all stored board data outside of monday.com will be permanently removed and the widget will no
            longer be publicly accessible. Don't forget to remove any associated embed codes.
          </Flex>
        </Box>

        <Box marginBottom={Box.marginBottoms?.MEDIUM}>
          <Flex gap={16}>
            <Button
              disabled={isUnpublishing}
              size={Button.sizes?.LARGE}
              kind={Button.kinds?.PRIMARY}
              color={Button.colors?.NEGATIVE}
              onClick={async () => {
                onClose(true);
              }}
              style={{
                width: '100%',
              }}
            >
              Unpublish widget
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
}
