import { useState } from 'react';
import { Flex, Button, Box, Loader, Icon, LinearProgressBar, Modal, ModalHeader, ModalContent, Checkbox } from 'monday-ui-react-core';
import { monday } from '../services/monday';
import { ViewerBoardInfo, ViewerBoardItem, Settings, ViewerAsset } from '@gorilla/widgets-viewer/app/viewer/types';
import { removeUnusedAssets } from '@gorilla/widgets-shared/src/services/publish';
import { ShareBox } from './ShareBox';
import { usePublish } from '../hooks/use-publish';

// @ts-ignore-next-line
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
// @ts-ignore-next-line
import CompletedIcon from 'monday-ui-react-core/dist/icons/Completed';
// @ts-ignore-next-line
import FileIcon from 'monday-ui-react-core/dist/icons/File';

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

type PublishModalProps = {
  show: boolean;
  boardInfo: ViewerBoardInfo;
  items: ViewerBoardItem[];
  assets: ViewerAsset[];
  settings: Settings;
  isPublished: boolean;
  widgetInfo: any;
  path: string;
  onClose: () => void;
};

export function PublishModal({ show, onClose, boardInfo, items, assets, settings, path, isPublished, widgetInfo }: PublishModalProps) {
  const [agreeTOS, setAgreeTOS] = useState<boolean | false>(false);
  const [showFiles, setShowFiles] = useState<boolean | false>(false);
  const [showLog, setShowLog] = useState<boolean | false>(false);
  const { id, publish, progress, errorMessage, log } = usePublish();
  const usedAssets = removeUnusedAssets(assets, boardInfo, items, settings) as ViewerAsset[];

  let status = 'init';

  if (errorMessage) {
    status = 'error';
  } else if (id !== null) {
    status = 'published';
  } else if (progress !== null) {
    status = 'publishing';
  }

  let buttonText = 'Publish widget';

  if (status === 'published') {
    buttonText = 'Published';
  } else if (status === 'publishing') {
    buttonText = 'Publish widget';
  }

  let filesList = null;
  let logList = null;

  const hasWarnings = (log || []).some((entry) => entry.type === 'warning');

  if (showFiles) {
    filesList = (
      <Box marginTop={Box.marginTops?.SMALL}>
        <Box
          padding={Box.paddings?.LARGE}
          paddingTop={Box.paddingTops?.MEDIUM}
          border={Box.borders?.DEFAULT}
          borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR}
          rounded={Box.roundeds?.SMALL}
          backgroundColor={Box.backgroundColors?.GREY_BACKGROUND_COLOR}
        >
          <div style={{ width: 470 }}>
            {usedAssets.map((asset) => {
              return (
                <div key={asset.id} style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '12px' }}>
                  <Icon icon={FileIcon} />{' '}
                  <div style={{ overflow: 'hidden', maxWidth: '350px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{asset.name}</div>{' '}
                  <span style={{ color: '#afb0bd' }}>({formatBytes(asset.size)})</span>
                </div>
              );
            })}
          </div>
        </Box>
      </Box>
    );
  }

  if (showLog && (status === 'published' || status === 'error')) {
    logList = (
      <Box marginTop={Box.marginTops?.SMALL}>
        <div style={{ width: 520 }}>
          {log.map((entry) => {
            return (
              <div key={entry.message} style={{ fontSize: '12px' }}>
                {entry.message}
                {entry.type === 'error' ? <span style={{ color: '#e73a3a' }}> (Error)</span> : null}
                {entry.type === 'warning' ? <span style={{ color: 'orange' }}> (Warning)</span> : null}
              </div>
            );
          })}
        </div>
      </Box>
    );
  }

  const assetsTotalSize = usedAssets.reduce((acc, asset) => {
    return acc + asset.size;
  }, 0);

  return (
    <Modal
      contentSpacing
      alertDialog={true}
      data-testid="publishModal"
      width={Modal.width?.DEFAULT}
      show={show}
      onClose={() => {
        if (status !== 'publishing') {
          onClose();
        }
      }}
    >
      <ModalHeader data-testid="publishModalHeader" title="Publish widget" description="" closeButtonAriaLabel="" />
      <ModalContent>
        <Box marginBottom={Box.marginBottoms?.LARGE}>
          <Flex gap={8} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
            <Box>
              <>
                The widget will appear as is after publishing, including all board items and related files from the boards current state. It
                remains published until it is deleted or until 5 days after the app was uninstalled. Changes made after publishing, need to
                be republished to take effect.
              </>
            </Box>
            {/* 
            {status === 'init' ? (
              <Box>
                <>
                  {assets.length ? (
                    <Flex gap={5}>
                      <a
                        href=""
                        onClick={(ev) => {
                          ev.preventDefault();
                          setShowFiles(!showFiles);
                        }}
                      >
                        {showFiles ? 'Hide' : 'Show'} files
                      </a>
                      <div style={{ fontSize: '14px' }}>
                        ({formatBytes(assetsTotalSize)})
                      </div>
                    </Flex>
                  ) : null}

                  {filesList}
                </>
              </Box>
            ) : null}
            */}

            {status === 'init' ? (
              <>
                <Box marginTop={Box.marginTops?.SMALL}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', position: 'relative' }}>
                    <Checkbox
                      checked={agreeTOS}
                      label=" "
                      onChange={() => {
                        {
                          agreeTOS === false ? setAgreeTOS(true) : setAgreeTOS(false);
                        }
                      }}
                    />
                    <small>
                      I accept the{' '}
                      <a href="https://getgorilla.app/privacy/" target="_blank">
                        Privacy Policy
                      </a>
                      , the{' '}
                      <a href="https://getgorilla.app/terms/" target="_blank">
                        Terms of Service
                      </a>{' '}
                      and that all board items
                      <br />
                      {usedAssets.length ? (
                        <>
                          including{' '}
                          <a
                            href=""
                            onClick={(ev) => {
                              ev.preventDefault();
                              setShowFiles(!showFiles);
                            }}
                          >
                            Files
                          </a>{' '}
                          ({formatBytes(assetsTotalSize)})&nbsp;
                        </>
                      ) : null}
                      will be processed and stored outside of monday.com
                    </small>
                  </div>
                </Box>
                {filesList}
              </>
            ) : null}
          </Flex>
        </Box>

        {/* PUBLISHING */}

        {status !== 'init' ? (
          <Box marginBottom={Box.marginBottoms?.SMALL}>
            <LinearProgressBar
              value={progress}
              size={LinearProgressBar.sizes.LARGE}
              barStyle={status === 'published' ? LinearProgressBar.styles.POSITIVE : LinearProgressBar.styles.PRIMARY}
            />
          </Box>
        ) : (
          <></>
        )}

        {status !== 'init' ? (
          <Box marginBottom={Box.marginBottoms?.MEDIUM}>
            <Flex gap={7}>
              {status === 'publishing' ? (
                <>
                  <Loader size={14} color={Loader.colors.PRIMARY} />
                  <small style={{ display: 'block', lineHeight: '20px', color: '#0073ea' }}>Publishing widget</small>
                </>
              ) : null}
              {status === 'published' ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', color: '#00854d' }}>
                    <Icon icon={CompletedIcon} clickable={false} />
                  </div>
                  <small style={{ display: 'block', lineHeight: '20px', color: '#00854d' }}>
                    Widget published {hasWarnings ? <span style={{ color: 'orange' }}> (with warnings)</span> : null}
                  </small>{' '}
                  <br />
                  <a
                    href=""
                    onClick={(ev) => {
                      ev.preventDefault();
                      setShowLog(!showLog);
                    }}
                  >
                    {showLog ? 'Hide' : 'Show'} log
                  </a>
                </>
              ) : null}
              {status === 'error' ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', color: '#e73a3a' }}>
                    <Icon icon={AlertIcon} clickable={false} />
                  </div>
                  <small style={{ display: 'block', lineHeight: '20px', color: '#e73a3a' }}>{errorMessage}</small> <br />
                  <a
                    href=""
                    onClick={(ev) => {
                      ev.preventDefault();
                      setShowLog(!showLog);
                    }}
                  >
                    {showLog ? 'Hide' : 'Show'} log
                  </a>
                </>
              ) : null}
            </Flex>
            <>{logList}</>
          </Box>
        ) : (
          <></>
        )}

        {!id ? (
          <Box>
            <Flex gap={16}>
              <Button
                disabled={agreeTOS === false || progress !== null}
                size={Button.sizes?.LARGE}
                kind={Button.kinds?.PRIMARY}
                onClick={async () => {
                  monday.execute('valueCreatedForUser');
                  await publish(settings, boardInfo, items, path);
                }}
                style={{
                  width: '100%',
                }}
              >
                {buttonText}
              </Button>
            </Flex>
          </Box>
        ) : (
          <></>
        )}

        {isPublished || id ? (
          <ShareBox
            widgetId={widgetInfo?.id || id}
            introText={
              id ? (
                <Box marginBottom={Box.marginBottoms?.MEDIUM}>
                  <h4 style={{ color: '#0073ea' }}>Congratulations, your widget was published.</h4>
                  <small style={{ display: 'block', marginTop: '8px', lineHeight: '20px' }}>
                    If changes are made to the board now, the widget must be republished that changes take effect. The share link and embed
                    code are always preserved.
                  </small>
                </Box>
              ) : null
            }
          />
        ) : (
          <></>
        )}
      </ModalContent>
    </Modal>
  );
}
