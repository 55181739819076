export const DEFAULT_IMAGE_FORMAT_ID = '16:9_LARGE';

export const IMAGE_FORMATS = [
  {
    label: 'Panorama 16:9',
    active: true,
    id: '16:9_LARGE',
    resizing_type: 'fill',
    width: 1024,
    height: 576,
  },
  {
    label: '16:9, Medium',
    id: '16:9_MEDIUM',
    resizing_type: 'fill',
    width: 640,
    height: 360,
  },
  {
    label: '16:9, Small',
    id: '16:9_SMALL',
    resizing_type: 'fill',
    width: 320,
    height: 180,
  },
  {
    label: 'Square 1:1',
    active: true,
    id: '1:1_LARGE',
    resizing_type: 'fill',
    width: 1024,
    height: 1024,
  },
  {
    label: '1:1, Medium',
    id: '1:1_MEDIUM',
    resizing_type: 'fill',
    width: 640,
    height: 640,
  },
  {
    label: '1:1, Small',
    id: '1:1_SMALL',
    resizing_type: 'fill',
    width: 320,
    height: 320,
  },
  {
    label: 'Default 4:3',
    active: true,
    id: '4:3_LARGE',
    resizing_type: 'fill',
    width: 1024,
    height: 768,
  },
  {
    label: '4:3, Medium',
    id: '4:3_MEDIUM',
    resizing_type: 'fill',
    width: 640,
    height: 480,
  },
  {
    label: '4:3, Small',
    id: '4:3_SMALL',
    resizing_type: 'fill',
    width: 320,
    height: 240,
  },
  {
    label: 'Original',
    active: true,
    id: '1:AUTO_LARGE',
    resizing_type: 'fill',
    width: 1024,
    height: 0,
  },
  {
    label: 'Original Format, Medium',
    id: '1:AUTO_MEDIUM',
    resizing_type: 'fill',
    width: 640,
    height: 0,
  },
  {
    label: 'Original Format, Small',
    id: '1:AUTO_SMALL',
    resizing_type: 'fill',
    width: 320,
    height: 0,
  },
];

export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.avif', '.svg'];
