import { Flex, Box, TextField } from 'monday-ui-react-core';

// @ts-ignore-next-line
import DuplicateIcon from 'monday-ui-react-core/dist/icons/Duplicate';
// @ts-ignore-next-line
import LinkIcon from 'monday-ui-react-core/dist/icons/Link';
// @ts-ignore-next-line
import EmbedIcon from 'monday-ui-react-core/dist/icons/Embed';

type ShareBoxProps = {
  widgetId: string;
  introText?: React.ReactNode;
};

export function ShareBox({ widgetId, introText }: ShareBoxProps) {
  const shareUrl = `${import.meta.env.VITE_WIDGETS_VIEWER_URL}/${widgetId}`;

  const embedCode = `<script src="${import.meta.env.VITE_WIDGETS_VIEWER_URL}/resizer/iframeResizer.min.js"></script>
<iframe id="widget-${widgetId}" src="${shareUrl}?type=embed" style="width:1px;min-width:100%;border:0;"></iframe>
<script>iFrameResize({ log: false }, '#widget-${widgetId}');</script>`;

  return (
    <Box
      marginTop={Box.marginTops?.LARGE}
      padding={Box.paddings?.LARGE}
      paddingTop={Box.paddingTops?.MEDIUM}
      border={Box.borders?.DEFAULT}
      borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR}
      rounded={Box.roundeds?.SMALL}
      backgroundColor={Box.backgroundColors?.GREY_BACKGROUND_COLOR}
    >
      {introText}

      <Box marginBottom={Box.marginBottoms?.SMALL}>
        <Flex gap={8} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
          <TextField
            readonly
            size={TextField.sizes.MEDIUM}
            title="Share link"
            value={shareUrl}
            labelIconName={LinkIcon}
            iconName={DuplicateIcon}
            onIconClick={() => {
              navigator.clipboard.writeText(shareUrl);
            }}
            className="CustomInput"
          />
        </Flex>
      </Box>
      <Box>
        <Flex gap={8} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
          <TextField
            readonly
            className="CustomInput"
            size={TextField.sizes.MEDIUM}
            title="Embed code"
            value={embedCode}
            labelIconName={EmbedIcon}
            iconName={DuplicateIcon}
            onIconClick={() => {
              navigator.clipboard.writeText(embedCode);
            }}
          />
        </Flex>
      </Box>
    </Box>
  );
}
