import { useState } from 'react';

export function PublishedIframe({ src }: { src: string }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && (
        <div className="LoadingWrapper">
          <div className="LoadingWrapper--Loader"></div>
        </div>
      )}
      <iframe
        src={src}
        id="viewer-iframe"
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </>
  );
}
