import { useRef, useState, useEffect } from 'react';
import * as _ from 'lodash';
import { getAssets, Asset } from '@gorilla/common/src/lib/monday-api/api';
import { upload, removeUnusedAssets } from '@gorilla/widgets-shared/src/services/publish';
import { mondayClient, getSessionToken } from '../services/monday';
import { getWidgetInfo } from '../services/widgets';
import { ViewerBoardInfo, ViewerBoardItem, Settings } from '@gorilla/widgets-viewer/app/viewer/types';

export function usePublish() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [log, setLog] = useState<{ type: string; message: string }[]>([]);
  const [progress, setProgress] = useState<any>(null);
  const [id, setId] = useState<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  async function publish(settings: Settings, boardInfo: ViewerBoardInfo, items: ViewerBoardItem[], path: string) {
    setProgress(0);

    const localLog: { type: string; message: string }[] = [];

    function hasLogErrors() {
      return localLog.some((entry) => entry.type === 'error');
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    let sessionToken = null;

    try {
      sessionToken = await getSessionToken();
    } catch (err) {
      localLog.push({ type: 'error', message: 'Failed to get session token' });
    }

    let assets: Asset[] = [];

    if (!hasLogErrors()) {
      try {
        const assetIds = _.uniq(
          _.flatten(
            items.map((item) =>
              _.flatten(
                Object.values(item.values)
                  // @ts-ignore
                  .map((value) => value.value.asset_ids || []),
              ),
            ),
          ),
        );

        if (assetIds.length) {
          assets = ((await getAssets(mondayClient, assetIds)) || []).map((asset) => {
            return {
              ...asset,
              id: String(asset.id),
            };
          });
        }
      } catch (err) {
        localLog.push({ type: 'error', message: 'Failed to get assets' });
      }
    }

    if (!hasLogErrors()) {
      const onProgress = (progress: number) => {
        setProgress(progress);
      };

      // remove assets that are not used
      const usedAssets = removeUnusedAssets(assets, boardInfo, items, settings) as Asset[];

      try {
        const uploadLog = await upload(
          import.meta.env.VITE_PUBLISHER_ENDPOINT_URL,
          sessionToken,
          path,
          settings,
          boardInfo,
          items,
          usedAssets,
          onProgress,
          abortController.signal,
        );

        localLog.push(...uploadLog);
      } catch (err) {
        console.log('err', err);
        localLog.push({ type: 'error', message: 'Failed to upload assets' });
      }
    }

    if (!hasLogErrors()) {
      try {
        const { id } = await getWidgetInfo(path);
        setProgress(100);
        setId(id);
      } catch (err) {
        localLog.push({ type: 'error', message: 'The publish endpoint responded with an error' });
      }
    }

    setLog(localLog);

    if (hasLogErrors()) {
      setErrorMessage('Failed to publish widget');
    }
  }

  return {
    errorMessage,
    id,
    publish,
    progress,
    log,
  };
}
