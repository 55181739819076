import { Settings } from './types';

function getBodyPadding(size: string) {
  if (size === 'small') {
    return '25px';
  }

  if (size === 'medium') {
    return '50px';
  }

  if (size === 'large') {
    return '75px';
  }

  return '0';
}

function getTableCellPadding(size: string) {
  if (size === 'small') {
    return '5px';
  }

  if (size === 'medium') {
    return '10px';
  }

  if (size === 'large') {
    return '15px';
  }

  return '0';
}

function getTableBorderRadius(size: string) {
  if (size === 'small') {
    return '5px';
  }

  if (size === 'medium') {
    return '10px';
  }

  if (size === 'large') {
    return '15px';
  }

  return '0';
}

function getGridItemPadding(size: string) {
  if (size === 'small') {
    return '15px';
  }

  if (size === 'medium') {
    return '25px';
  }

  if (size === 'large') {
    return '35px';
  }

  return '0';
}

function getGridItemGap(size: string) {
  if (size === 'small') {
    return '15px';
  }

  if (size === 'medium') {
    return '25px';
  }

  if (size === 'large') {
    return '35px';
  }

  return '0';
}

function getGridItemBorderRadius(size: string) {
  if (size === 'small') {
    return '5px';
  }

  if (size === 'medium') {
    return '10px';
  }

  if (size === 'large') {
    return '15px';
  }

  return '0';
}

{
  /* https://css-tricks.com/snippets/javascript/lighten-darken-color/ or https://natclark.com/tutorials/javascript-lighten-darken-hex-color/ */
}

function getTableHoverColor(color: string) {
  let opacity = 0.07;
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function settingsToCSSVariables(settings: Settings) {
  return {
    'primary-color': settings.primaryColor,
    'secondary-color': settings.secondaryColor,
    'border-color': settings.borderColor,
    'background-color': settings.backgroundColor,
    'font-family': settings.fontFamily,
    'font-size': `${settings.fontSize}px`,

    'body-padding': getBodyPadding(settings.bodyPadding),

    'grid-item-padding': getGridItemPadding(settings.gridItemPadding),
    'grid-item-gap': getGridItemGap(settings.gridItemGap),
    'grid-item-border-radius': getGridItemBorderRadius(settings.gridItemBorderRadius),

    'table-cell-padding': getTableCellPadding(settings.tableCellPadding),
    'table-border-radius': getTableBorderRadius(settings.tableBorderRadius),
    'table-hover-color': getTableHoverColor(settings.primaryColor),
  };
}

export function formatNumber(num: number) {
  // use Intl to format number in us style
  return new Intl.NumberFormat('en-US').format(num);
}
