import { getSessionToken } from './monday';

async function getAuthHeaders() {
  const sessionToken = await getSessionToken();

  return {
    Authorization: `${sessionToken}`,
  };
}

export async function handleFetchErrorResponse(message: string, res: Response) {
  if (!res.ok) {
    const error = new Error(message);
    // Attach extra info to the error object.
    try {
      (error as any).info = await res.text();
    } catch (err) {}

    (error as any).status = res.status;

    throw error;
  }
}

export async function unpublishWidget(path: string) {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_PUBLISHER_ENDPOINT_URL}/widget/unpublish`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path }),
  });

  handleFetchErrorResponse('Failed to unpublish widget', response);

  return await response.text();
}

export async function getWidgetInfo(path: string) {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_PUBLISHER_ENDPOINT_URL}/widget/info`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path }),
  });

  handleFetchErrorResponse('Failed to get widget info', response);

  return (await response.json()) as { id: string; publishedAt: number | undefined };
}
