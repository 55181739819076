import { useMemo, useEffect, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  FilterFn,
} from '@tanstack/react-table';
import { Settings, ViewerBoardInfo, ViewerBoardItem, ViewerBoardField, ViewerAsset } from './types';
import { Grid } from './Grid';
import { Table } from './Table';
import { Value } from './Value';
import { DebouncedInput } from './DebouncedInput';
import { Pagination } from './Pagination';
import { useViewer } from './hooks/use-viewer';
import { settingsToCSSVariables } from './utils';
import { rankItem, rankings } from '@tanstack/match-sorter-utils';

const GRID_ITEMS_PER_LOAD = 20;
const TABLE_ITEMS_PER_PAGE_DEFAULT = 10;

type AssetsById = Record<string, ViewerAsset>;

function getValue(row: ViewerBoardItem, field: ViewerBoardField, settings: Settings) {
  if (field.type === 'group') {
    return row.group?.title;
  }

  if (field.type === 'name') {
    return row.name;
  }

  //if (field.type === 'tags' || field.type === 'file' || field.type === 'link' || field.type === 'email') {
  if (field.type === 'file' || field.type === 'link' || field.type === 'email') {
    return row.values[field.id]?.value;
  }

  if (field.type === 'mirror') {
    const columnSettings = settings.columnSettings[field.id] || {};
    const summarizeNumbers = !!columnSettings.summarizeNumbers;

    if (summarizeNumbers) {
      const text = (row.values[field.id]?.value as any)?.text;

      if (!text) {
        return null;
      }

      try {
        const numbers = text.split(', ').flatMap((n: string) => (n ? [parseFloat(n.trim())] : []));
        const sum = numbers.reduce((acc: number, n: number) => acc + n, 0);

        return sum;
      } catch (err) {}
    }
  }

  return (row.values[field.id]?.value as { text: string })?.text;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value, { threshold: rankings.CONTAINS });

  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

const columnHelper = createColumnHelper<ViewerBoardItem>();

function getSortColumn(settings: Settings, boardInfo: ViewerBoardInfo) {
  return settings.sortColumn && boardInfo.fields.find((f) => f.id === settings.sortColumn) ? settings.sortColumn : 'name';
}

type ViewerProps = {
  settings: Settings;
  boardInfo: ViewerBoardInfo;
  boardItems: ViewerBoardItem[];
  assets: ViewerAsset[];
  widgetId: string;
  mode: 'preview' | 'publish';
  previewTumbnailGeneratorUrl?: string;
  styles?: string;
};

export function Viewer({ settings, boardInfo, boardItems, mode, previewTumbnailGeneratorUrl, assets, widgetId, styles }: ViewerProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const assetsById = useMemo(() => {
    return assets?.reduce((prev, curr) => {
      return { ...prev, [curr.id]: curr };
    }, {} as AssetsById);
  }, [assets]);

  const columns = boardInfo.fields
    .filter((field) => {
      const columnSettings = settings?.columnSettings || {};
      const fieldSettings = columnSettings[field.id] || {};

      if (settings.mode === 'grid' && settings.gridItemHeadlineColumn === field.id) {
        return true;
      }

      return !fieldSettings.hidden;
    })
    .map((field) => {
      return columnHelper.accessor((row) => getValue(row, field, settings), {
        id: field.id,
        cell: (cell) => {
          const value = cell.getValue();
          const columnSettings = settings?.columnSettings || {};
          const fieldSettings = columnSettings[field.id] || {};

          if (fieldSettings && fieldSettings.hidden) {
            return null;
          }

          return (
            <Value
              field={field}
              value={value}
              settings={settings}
              mode={mode}
              widgetId={widgetId}
              assetsById={assetsById || {}}
              previewTumbnailGeneratorUrl={previewTumbnailGeneratorUrl}
              columnSettings={columnSettings}
            />
          );
        },
        header: () => field.label,
      });
    });

  const table = useReactTable({
    data: boardItems,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter: searchTerm,
    },
    initialState: {
      pagination: {
        pageSize: settings.mode === 'grid' ? GRID_ITEMS_PER_LOAD : TABLE_ITEMS_PER_PAGE_DEFAULT,
      },
      sorting: [
        {
          id: getSortColumn(settings, boardInfo),
          desc: settings.sortDirection === 'desc',
        },
      ],
    },
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setSearchTerm,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { setCSSVariables } = useViewer();

  useEffect(() => {
    if (!settings?.mode) {
      return;
    }

    if (settings?.mode === 'grid') {
      table.setPageSize(GRID_ITEMS_PER_LOAD);
      setSearchTerm('');
    } else if (settings?.mode === 'table') {
      table.setPageSize(TABLE_ITEMS_PER_PAGE_DEFAULT);
    }

    table.setSorting([
      {
        id: getSortColumn(settings, boardInfo),
        desc: settings.sortDirection === 'desc',
      },
    ]);
  }, [settings?.mode]);

  useEffect(() => {
    table.setSorting([
      {
        id: getSortColumn(settings, boardInfo),
        desc: settings.sortDirection === 'desc',
      },
    ]);
  }, [settings.sortColumn, settings.sortDirection]);

  useEffect(() => {
    if (!settings) {
      return;
    }

    setCSSVariables(settingsToCSSVariables(settings));
  }, [settings]);

  const currentPage = table.getState().pagination.pageIndex;
  const pageCount = table.getPageCount();

  return (
    <>
      {styles ? <style dangerouslySetInnerHTML={{ __html: styles }} /> : null}
      <div className="Viewer">
        {settings.search ? (
          <div className={settings.mode === 'table' ? 'WidgetHeader WidgetHeader--Table' : 'WidgetHeader WidgetHeader--Grid'}>
            <div className="Search">
              <DebouncedInput
                className="SearchInput"
                type="text"
                placeholder="Search"
                onChange={(value) => setSearchTerm(String(value))}
                value={searchTerm}
              />
            </div>
          </div>
        ) : null}

        {settings.mode === 'table' ? <Table settings={settings} columns={columns} table={table} /> : null}
        {settings.mode === 'grid' ? (
          <Grid
            mode={mode}
            previewTumbnailGeneratorUrl={previewTumbnailGeneratorUrl}
            settings={settings}
            columns={columns}
            table={table}
            assetsById={assetsById}
          />
        ) : null}

        {settings.mode === 'table' ? (
          <div className="WidgetFooter">
            <div className="PaginationWrapper">
              <div className="Items">
                <select
                  className="ItemsSelect"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <div className="Pagination">
                <button className="PaginationButton" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                  <i className="fa-regular fa-angle-left"></i>
                </button>
                <Pagination currentPage={currentPage} pageCount={pageCount} onPageClick={(pageIndex) => table.setPageIndex(pageIndex)} />
                <button className="PaginationButton" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                  <i className="fa-regular fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {settings.mode === 'grid' && table.getCanNextPage() ? (
          <div className="WidgetFooter WidgetFooter--Grid">
            <div
              className="LoadMoreButton"
              onClick={() => {
                table.setPageSize(table.getState().pagination.pageSize + GRID_ITEMS_PER_LOAD);
              }}
            >
              Load more
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
