import * as React from 'react';

// This code is copied from https://github.com/askides/react-plock/blob/main/packages/react-plock/src/Plock.tsx

function getWindowDefault() {
  return typeof window !== 'undefined' ? window : null;
}

export function useMediaValues(medias: number[] | undefined, columns: number[], getWindow: typeof getWindowDefault) {
  const [values, setValues] = React.useState({ columns: 3 });
  const win = getWindow();

  React.useEffect(() => {
    if (!medias || !win) {
      setValues({ columns: 3 });
      return;
    }

    const mediaQueries = medias.map((media) => win.matchMedia(`(min-width: ${media}px)`));

    const onSizeChange = () => {
      let idx = -1;

      mediaQueries.forEach((mediaQuery) => {
        if (mediaQuery.matches) {
          idx++;
        }
      });

      if (idx === -1) {
        idx = 0;
      }

      setValues({ columns: columns[idx] });
    };

    // Initial Call
    onSizeChange();

    // Apply Listeners
    for (const mediaQuery of mediaQueries) {
      mediaQuery.addEventListener('change', onSizeChange);
    }

    return () => {
      for (const mediaQuery of mediaQueries) {
        mediaQuery.removeEventListener('change', onSizeChange);
      }
    };
  }, [values.columns]);

  return values;
}

export type MasonryProps<T> = React.ComponentPropsWithoutRef<'div'> & {
  mode: string;
  items: T[];
  render: (item: T, idx: number) => React.ReactNode;
  getWindow?: typeof getWindowDefault;
  config: {
    columns: number | number[];
    media?: number[];
  };
};

export function createSafeArray(data: number | number[]) {
  return Array.isArray(data) ? data : [data];
}

export function Masonry<T>({ mode, items = [], render, config, getWindow = getWindowDefault, ...rest }: MasonryProps<T>) {
  const grid = mode;
  const { columns } = useMediaValues(config.media, createSafeArray(config.columns), getWindow);

  const chunks = createChunks<T>(items, columns);
  const dataColumns = createDataColumns<T>(chunks, columns);

  let content = null;

  if (grid === 'masonry') {
    content = dataColumns.map((column, idx) => <MasonryRow key={idx}>{column.map((item, idx) => render(item, idx))}</MasonryRow>);
  } else {
    content = items.map((item, idx) => render(item, idx));
  }

  return (
    <div
      {...rest}
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
      }}
      className={grid === 'masonry' ? 'grid--masonry' : 'grid--grid'}
    >
      {content}
    </div>
  );
}

export function MasonryRow({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr)',
      }}
      className="grid--masonry_column"
    >
      {children}
    </div>
  );
}

export function createChunks<T>(data: T[] = [], columns = 3) {
  const result = [];

  for (let idx = 0; idx < data.length; idx += columns) {
    const slice = data.slice(idx, idx + columns);
    result.push(slice);
  }

  return result;
}

export function createDataColumns<T>(data: T[][] = [], columns = 3) {
  const result = Array.from<T[], T[]>({ length: columns }, () => []);

  for (let idx = 0; idx < columns; idx++) {
    for (let jdx = 0; jdx < data.length; jdx += 1) {
      if (data[jdx][idx]) {
        result[idx].push(data[jdx][idx]);
      }
    }
  }

  return result;
}
