import { useState } from 'react';
import { Flex, Box, Icon } from 'monday-ui-react-core';
// @ts-ignore-next-line
import DropdownChevronDownIcon from 'monday-ui-react-core/dist/icons/DropdownChevronDown';
// @ts-ignore-next-line
import DropdownChevronUpIcon from 'monday-ui-react-core/dist/icons/DropdownChevronUp';

type SidebarWidgetProps = {
  title: string;
  description: string;
  children: React.ReactNode;
  wrapChildren?: boolean;
  collapsed?: boolean;
  icon: any;
};

export function SidebarWidget({ title, description, children, wrapChildren = true, collapsed = false, icon }: SidebarWidgetProps) {
  const [collapseWidget, setCollapseWidget] = useState<boolean | false>(collapsed);

  return (
    <div className={!collapseWidget ? 'SidebarWidget' : 'SidebarWidget SidebarWidget--Collapsed'}>
      <Box marginBottom={!collapseWidget ? Box.marginBottoms?.MEDIUM : Box.marginBottoms?.AUTO} className="SidebarWidget--Header">
        <Flex gap={8} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
          <Flex
            gap={8}
            style={{ width: '100%' }}
            onClick={() => {
              {
                collapseWidget ? setCollapseWidget(false) : setCollapseWidget(true);
              }
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#323338',
              }}
              className="DropdownIcon"
            >
              <Icon icon={icon} iconSize={20} clickable={false} />
            </div>
            <h5 style={{ flex: 1 }}>{title}</h5>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#323338',
              }}
              className="DropdownIcon"
            >
              <Icon icon={!collapseWidget ? DropdownChevronUpIcon : DropdownChevronDownIcon} iconSize={28} clickable={false} />
            </div>
          </Flex>
          {!collapseWidget ? <span className="SidebarWidget__Description">{description}</span> : null}
        </Flex>
      </Box>
      {!collapseWidget ? (
        <>
          {wrapChildren ? (
            <div>
              <Flex gap={10} direction={Flex.directions?.COLUMN} align={Flex.align?.START} style={{ width: '100%' }}>
                <>{children}</>
              </Flex>
            </div>
          ) : (
            children
          )}
        </>
      ) : null}
    </div>
  );
}
