import { Flex, Dropdown, IconButton } from 'monday-ui-react-core';
// @ts-ignore-next-line
import LearnMoreIcon from 'monday-ui-react-core/dist/icons/LearnMore';

type Option = {
  label: string;
  value: string | number;
  leftIcon?: any | null;
};

type SelectSettingProps = {
  title: string;
  value: Option['value'] | null;
  options: Option[];
  onChange: (value: Option['value']) => void;
  setHelpState: any;
  settingCategory: string;
  setShowHelpModal: any;
};

export function SelectSetting({ title, value, options, onChange, setHelpState, settingCategory, setShowHelpModal }: SelectSettingProps) {
  let selectedOption = options.find((option) => option.value === value);

  if (!selectedOption) {
    selectedOption = options[0];
  }

  return (
    <Flex direction={Flex.directions?.COLUMN} align={Flex.align.START} style={{ width: '100%' }}>
      <label style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
        <small>{title}</small>
        <IconButton
          className="CustomHelpButton"
          icon={LearnMoreIcon}
          kind={IconButton.kinds.TERTIARY}
          size={IconButton.sizes.XXS}
          onClick={() => {
            setHelpState({ category: settingCategory, content: title.toLowerCase().replace(/[^a-z]/g, '_') });
            setShowHelpModal(true);
          }}
        />
      </label>
      <Dropdown
        className="CustomDropdown"
        onChange={(newOption: Option) => {
          newOption.value && onChange(newOption.value);
        }}
        size={Dropdown.sizes?.MEDIUM}
        options={options}
        value={selectedOption}
        clearable={false}
      />
    </Flex>
  );
}
