import { useState } from 'react';
import { Flex, Box, Icon, Modal, ModalHeader, ModalContent, List, ListItemIcon, ListItem, Dropdown } from 'monday-ui-react-core';

// @ts-ignore-next-line
import DropdownChevronRightIcon from 'monday-ui-react-core/dist/icons/DropdownChevronRight';
// @ts-ignore-next-line
import HighlightColorBucketIcon from 'monday-ui-react-core/dist/icons/HighlightColorBucket';
// @ts-ignore-next-line
import SettingsIcon from 'monday-ui-react-core/dist/icons/Settings';
// @ts-ignore-next-line
import TableIcon from 'monday-ui-react-core/dist/icons/Table';
// @ts-ignore-next-line
import WorkspaceIcon from 'monday-ui-react-core/dist/icons/Workspace';

type HelpModalProps = {
  show: boolean;
  onClose: () => void;
  category: string;
  content: string;
};

const helpOptions = [
  {
    label: 'General settings',
    value: 'general_settings',
    leftIcon: SettingsIcon,
  },
  {
    label: 'Table settings',
    value: 'table_settings',
    leftIcon: TableIcon,
  },
  {
    label: 'Grid settings',
    value: 'grid_settings',
    leftIcon: WorkspaceIcon,
  },
];

export function HelpModal({ show, onClose, category, content }: HelpModalProps) {
  //const [showHelpContent, setShowHelpContent] = useState<string>('general_settings');
  //const [showHelpContentDetails, setShowHelpContentDetails] = useState<string>('widget_margin');
  const [showHelpContent, setShowHelpContent] = useState(() => (category === '' ? 'general_settings' : category));
  const [showHelpContentDetails, setShowHelpContentDetails] = useState(() => (content === '' ? 'widget_margin' : content));

  var searchCategory = showHelpContent;
  var filterData = helpOptions.filter((item) => item.value.includes(searchCategory));

  return (
    <Modal
      contentSpacing
      alertDialog={true}
      width={'800px'}
      data-testid="helpModal"
      show={show}
      onClose={() => {
        onClose();
      }}
    >
      <ModalHeader data-testid="helpModalHeader" title="Documentation" description="" closeButtonAriaLabel="" />
      <ModalContent>
        <Box marginBottom={Box.marginBottoms?.LARGE}>
          <Flex gap={32} direction={Flex.directions?.ROW} align={Flex.align?.START} className="Help">
            <div className="Help--Sidebar">
              <div style={{ marginBottom: '16px' }}>
                <Dropdown
                  className="CustomDropdown"
                  onOptionSelect={(ev: any) => {
                    setShowHelpContent(ev.value);
                    {
                      ev.value === 'general_settings' ? setShowHelpContentDetails('widget_margin') : null;
                    }
                    {
                      ev.value === 'table_settings' ? setShowHelpContentDetails('show_search') : null;
                    }
                    {
                      ev.value === 'grid_settings' ? setShowHelpContentDetails('item_margin') : null;
                    }
                  }}
                  size={Dropdown.sizes?.MEDIUM}
                  options={helpOptions}
                  //defaultValue={[helpOptions[0]]}
                  defaultValue={[filterData[0]]}
                  clearable={false}
                />
              </div>

              {showHelpContent === 'general_settings' ? (
                <List>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('widget_margin');
                    }}
                    selected={showHelpContentDetails === 'widget_margin'}
                  >
                    Widget margin
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('primary_color');
                    }}
                    selected={showHelpContentDetails === 'primary_color'}
                  >
                    Primary color
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('secondary_color');
                    }}
                    selected={showHelpContentDetails === 'secondary_color'}
                  >
                    Secondary color
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('border_color');
                    }}
                    selected={showHelpContentDetails === 'border_color'}
                  >
                    Border color
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('background_color');
                    }}
                    selected={showHelpContentDetails === 'background_color'}
                  >
                    Background color
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('font_family');
                    }}
                    selected={showHelpContentDetails === 'font_family'}
                  >
                    Font family
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('font_size');
                    }}
                    selected={showHelpContentDetails === 'font_size'}
                  >
                    Font size
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('text_alignment');
                    }}
                    selected={showHelpContentDetails === 'text_alignment'}
                  >
                    Text alignment
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('sort_column');
                    }}
                    selected={showHelpContentDetails === 'sort_column'}
                  >
                    Sort column
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('sort_direction');
                    }}
                    selected={showHelpContentDetails === 'sort_direction'}
                  >
                    Sort direction
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                </List>
              ) : null}

              {showHelpContent === 'table_settings' ? (
                <List>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('show_search');
                    }}
                    selected={showHelpContentDetails === 'show_search'}
                  >
                    Show search
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('cell_margin');
                    }}
                    selected={showHelpContentDetails === 'cell_margin'}
                  >
                    Cell margin
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('table_radius');
                    }}
                    selected={showHelpContentDetails === 'table_radius'}
                  >
                    Table radius
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                </List>
              ) : null}

              {showHelpContent === 'grid_settings' ? (
                <List>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('item_margin');
                    }}
                    selected={showHelpContentDetails === 'item_margin'}
                  >
                    Item margin
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('item_gap');
                    }}
                    selected={showHelpContentDetails === 'item_gap'}
                  >
                    Item gap
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('item_radius');
                    }}
                    selected={showHelpContentDetails === 'item_radius'}
                  >
                    Item radius
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('item_image_column');
                    }}
                    selected={showHelpContentDetails === 'item_image_column'}
                  >
                    Item image column
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('item_image_format');
                    }}
                    selected={showHelpContentDetails === 'item_image_format'}
                  >
                    Item image format
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                  <ListItem
                    size={ListItem.sizes?.XXS}
                    onClick={() => {
                      setShowHelpContentDetails('item_headline_column');
                    }}
                    selected={showHelpContentDetails === 'item_headline_column'}
                  >
                    Item headline column
                    <div style={{ marginLeft: 'auto' }}>
                      <ListItemIcon icon={DropdownChevronRightIcon} margin={ListItemIcon.margin?.END} />
                    </div>
                  </ListItem>
                </List>
              ) : null}
            </div>
            <div className="Help--Content">
              <div className="Help--ContentDescription">
                {/* GENERAL SETTINGS */}

                {showHelpContent === 'general_settings' && showHelpContentDetails === 'widget_margin' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/widget_margin.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>widget margin</strong> is an optional space around the widget content. Setting it to <strong>none</strong>{' '}
                      is recommended, if you want to embed the widget seamlessly into a website.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'primary_color' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/primary_color.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>primary color</strong> is being used for regular text in your widget. Use the color picker{' '}
                      <Icon
                        iconType={Icon.type.SVG}
                        icon={HighlightColorBucketIcon}
                        iconSize={16}
                        clickable={false}
                        style={{ transform: 'translate3d(0,3px,0)' }}
                      />{' '}
                      or enter your preferred value manually, in case you have it at your hands.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'secondary_color' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/secondary_color.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>secondary color</strong> is being used for headlines, hover or active states in your widget. Use the color
                      picker{' '}
                      <Icon
                        iconType={Icon.type.SVG}
                        icon={HighlightColorBucketIcon}
                        iconSize={16}
                        clickable={false}
                        style={{ transform: 'translate3d(0,3px,0)' }}
                      />{' '}
                      or enter your preferred value manually, in case you have it at your hands.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'border_color' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/border_color.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>border color</strong> is being used on any borders in your widget. Use the color picker{' '}
                      <Icon
                        iconType={Icon.type.SVG}
                        icon={HighlightColorBucketIcon}
                        iconSize={16}
                        clickable={false}
                        style={{ transform: 'translate3d(0,3px,0)' }}
                      />{' '}
                      or enter your preferred value manually, in case you have it at your hands.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'background_color' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/background_color.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>background color</strong> is being used on your widget itself. It should match your websites background
                      color. Use the color picker{' '}
                      <Icon
                        iconType={Icon.type.SVG}
                        icon={HighlightColorBucketIcon}
                        iconSize={16}
                        clickable={false}
                        style={{ transform: 'translate3d(0,3px,0)' }}
                      />{' '}
                      or enter your preferred value manually, in case you have it at your hands.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'font_family' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/font_family.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>font family</strong> is being used for all text in your widget. There is currently no option to make use
                      of custom third party web fonts. Choose a font family that matches your brand or website best.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'font_size' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/font_size.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>font size</strong> is being used as for all text in your widget. Headlines and potential other highlighted
                      text scale automatically based on this base font size. The font size is a pixel based value.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'text_alignment' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/font_family.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>text alignment</strong> is being applied to all text in your widget across all layouts. You can make your
                      text aligned to the <strong>left</strong>, <strong>center</strong> and <strong>right</strong>. It is{' '}
                      <strong>left</strong> by default.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'sort_column' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/sort.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>sort column</strong> option lets you choose a column from your board. The default item order in your
                      widget will be defined by the selected board column. For example choose a <strong>status</strong>,{' '}
                      <strong>rating</strong> or a <strong>number</strong> column.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'general_settings' && showHelpContentDetails === 'sort_direction' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/sort.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>sort direction</strong> relates directly to <strong>sort column</strong>. Choose between{' '}
                      <strong>ascending</strong> or <strong>descending</strong>. If you have chosen a <strong>number</strong> sort column
                      e.g., items will go lowest to highest value and vice versa by default.
                    </div>
                  </>
                ) : null}

                {/* TABLE SETTINGS */}

                {showHelpContent === 'table_settings' && showHelpContentDetails === 'show_search' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/show_search.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>show search</strong> option toggles a search bar above your table widget. It is only available in the
                      table layout. It's recommended to make use of this option, if you want to make your table content searchable.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'table_settings' && showHelpContentDetails === 'cell_margin' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/cell_margin.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>cell margin</strong> defines the inner space of your table cells. It is only available in the table
                      layout. The smaller the cell margin, the more compact your table is being displayed.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'table_settings' && showHelpContentDetails === 'table_radius' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/table_radius.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>table radius</strong> defines the outer corner radius of your table. It is only available in the table
                      layout. The bigger the table radius, the more rounded your table is being displayed.
                    </div>
                  </>
                ) : null}

                {/* GRID SETTINGS */}

                {showHelpContent === 'grid_settings' && showHelpContentDetails === 'item_margin' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/item_margin.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>item margin</strong> defines the inner space of each grid item. It is only available in the grid layout.
                      The smaller the item margin, the more compact your grid is being displayed.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'grid_settings' && showHelpContentDetails === 'item_gap' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/item_gap.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>item gap</strong> defines the space between each grid item. It is only available in the grid layout. The
                      smaller the item gap, the more compact your grid is being displayed.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'grid_settings' && showHelpContentDetails === 'item_radius' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/item_radius.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>item radius</strong> defines the outer corner radius of each grid item. It is only available in the grid
                      layout. The bigger the item radius, the more rounded your items are being displayed.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'grid_settings' && showHelpContentDetails === 'item_image_column' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/item_image_column.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>item image column</strong> shows all file columns of your monday.com board. The first image of this column
                      is being used as main image for each grid item. Set it to <strong>none</strong> if you don't want to use images at
                      all.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'grid_settings' && showHelpContentDetails === 'item_image_format' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/item_image_format.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>item image format</strong> defines in which format images in your grid are being displayed. If you choose
                      the <strong>original</strong> format, images will be scaled down but not cropped in their height.
                    </div>
                  </>
                ) : null}
                {showHelpContent === 'grid_settings' && showHelpContentDetails === 'item_headline_column' ? (
                  <>
                    <div className="Help--ContentMedia">
                      <img src="/documentation/item_headline_column.webp" width="518" height="200" alt="" />
                    </div>
                    <div>
                      The <strong>item headline column</strong> shows all text columns of your monday.com board. The content of this column
                      is being used as main headline for each grid item. Set it to <strong>none</strong> if you don't want to use headlines
                      at all.
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
}
