import * as _ from 'lodash';
//import * as XLSX from 'xlsx-js-style';
import { ColumnHandler } from '../types';
import { handlersByType } from './index';
import { parseJson, parseString } from '../converters';
import { FormulaColumnType, Board, BoardItem as BoardItemGraphQl } from '../../monday-api/api';

export type FormulaColumnValue = {
  value: any; // the result of formula could be a string or a number
};

/*
// @ts-ignore
const S5SCalc = window.S5SCalc as any;

S5SCalc.set_XLSX(XLSX);

export function formulaExecuter(formula) {
  return S5SCalc.calculate(formula);
}
*/

// @ts-ignore
let formulaExecuterFn = (formula: string) => {
  return 'formulaExecuterFn not initialized';
};

export function setFormulaExecuterFn(fn: typeof formulaExecuterFn) {
  formulaExecuterFn = fn;
}

export function formulaExecuter(formula: string) {
  return formulaExecuterFn(formula);
}

function parseFormula(formulaFromMonday: string, item: BoardItemGraphQl, board: Board) {
  const placeholders = formulaFromMonday.match(/{(.*?)}/g);

  // replace placeholders with values
  const parsedFormula = _.reduce(
    placeholders,
    (acc, placeholder) => {
      let placeholderModifier: null | string = null;
      let placeholderName = placeholder.replace(/{|}/g, ''); // TODO: check if this regex is correct
      let placeholderPieces = placeholderName.split('#');

      if (placeholderPieces.length === 2) {
        placeholderName = placeholderPieces[0];
        placeholderModifier = placeholderPieces[1];
      }

      let placeholderValue;

      if (placeholderName === 'group') {
        placeholderValue = item?.group.title;
      } else if (placeholderName === 'name') {
        placeholderValue = item?.name;
      } else {
        const placeholderColumnValue = item?.column_values.find((columnValue) => columnValue.id === placeholderName);

        if (!placeholderColumnValue) {
          throw new Error('placeholder column value not found');
        }

        const placeholderHandler = handlersByType[placeholderColumnValue.type];

        if (!placeholderHandler.getFormulaValue) {
          throw new Error('placeholder handler does not have getFormulaValue');
        }

        // TODO: fix typing
        const placeholderEngineValue = placeholderHandler.convertValueFromMondayToEngine(placeholderColumnValue as any, item, board);

        placeholderValue = placeholderHandler.getFormulaValue(placeholderEngineValue as any, placeholderModifier, placeholderColumnValue); // TODO: fix this
      }

      let rule = null;

      if (Array.isArray(placeholderValue)) {
        rule = placeholderValue[1];
        placeholderValue = placeholderValue[0];
      }

      return acc.replace(
        placeholder,
        String(_.isString(placeholderValue) && rule !== 'as_number' ? `"${placeholderValue}"` : placeholderValue),
      );
    },
    formulaFromMonday,
  );

  return parsedFormula;
}

const handler: ColumnHandler<FormulaColumnType, FormulaColumnValue> = {
  type: 'formula',

  default_value_field: 'value',

  convertValueFromMondayToEngine: (columnValue, item, board) => {
    //console.log('formula convertValueFromMondayToEngine', columnValue, item, board, engineConfig);

    if (!item) {
      throw new Error('item is required in formula column handler');
    }

    if (!board) {
      throw new Error('board is required in formula column handler');
    }

    const boardColumn = board.columns.find((column) => column.id === columnValue.id);

    let formula;

    if (boardColumn?.settings_str) {
      const result = parseJson(boardColumn?.settings_str);
      formula = parseString(result?.formula);
    }

    if (!formula) {
      // TODO: find better way of logging
      return {
        value: null,
      };
    }

    // replace placeholders with values
    const parsedFormula = parseFormula(formula, item, board);
    const value = formulaExecuter(parsedFormula);

    if (_.get(value, 'message')) {
      console.log(`failed to calculate formula of column ${boardColumn?.id}`, value);
      throw new Error(_.get(value, 'message'));
    }

    return {
      value,
    };
  },

  getFormulaValue(engineValue) {
    return engineValue.value;
  },
};

export default handler;
