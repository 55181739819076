import { useState } from 'react';
import { Flex, Box, TextField, Icon, IconButton } from 'monday-ui-react-core';
import { ChromePicker } from 'react-color';

// @ts-ignore-next-line
import HighlightColorBucketIcon from 'monday-ui-react-core/dist/icons/HighlightColorBucket';
// @ts-ignore-next-line
import BulletIcon from 'monday-ui-react-core/dist/icons/Bullet';
// @ts-ignore-next-line
import LearnMoreIcon from 'monday-ui-react-core/dist/icons/LearnMore';

type ColorSettingProps = {
  title: string;
  value: string;
  onChange: (newValue: string) => void;
  setHelpState: any;
  settingCategory: string;
  setShowHelpModal: any;
};

export function ColorSetting({ title, value, onChange, setHelpState, settingCategory, setShowHelpModal }: ColorSettingProps) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <Box className="CustomBox">
      <Flex gap={0} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
        <label style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
          <small>{title}</small>
          <IconButton
            className="CustomHelpButton"
            icon={LearnMoreIcon}
            kind={IconButton.kinds.TERTIARY}
            size={IconButton.sizes.XXS}
            onClick={() => {
              setHelpState({ category: settingCategory, content: title.toLowerCase().replace(/[^a-z]/g, '_') });
              setShowHelpModal(true);
            }}
          />
        </label>
        <div style={{ position: 'relative', width: '100%' }}>
          <TextField
            wrapperClassName="CustomColorPickerInput"
            className="CustomInput"
            //title={(<small>{title}</small>) as any}
            iconName={HighlightColorBucketIcon}
            size={TextField.sizes.MEDIUM}
            maxLength={7}
            value={value}
            onBlur={(ev) => {
              // @ts-ignore-next-line
              onChange(ev.target.value as any);
            }}
            onIconClick={() => {
              setDisplayColorPicker(!displayColorPicker);
            }}
          />
          <div style={{ pointerEvents: 'none', position: 'absolute', top: '5px', left: 0, color: value }}>
            <Icon icon={BulletIcon} iconSize={30} clickable={false} />
          </div>

          {displayColorPicker ? (
            <div
              style={{
                zIndex: '2',
                position: 'absolute',
                top: '29px',
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={() => {
                  setDisplayColorPicker(false);
                }}
              />
              <ChromePicker
                disableAlpha
                color={value}
                onChangeComplete={(newColor) => {
                  onChange(newColor.hex);
                }}
              />
            </div>
          ) : null}
        </div>
      </Flex>
    </Box>
  );
}
