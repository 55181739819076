import { flexRender, ColumnDef, Table, Row } from '@tanstack/react-table';
import { DEFAULT_IMAGE_FORMAT_ID } from '@gorilla/widgets-shared/src/constants';
import { Masonry } from './Masonry';
import { Settings, ViewerBoardItem, ViewerAsset } from './types';
import { IMAGE_EXTENSIONS } from './Value';

type AssetsById = Record<string, ViewerAsset>;

type GridItemProps = {
  row: Row<ViewerBoardItem>;
  settings: Settings;
  assetsById: AssetsById;
  previewTumbnailGeneratorUrl?: string;
};

function GridItem({ row, settings, assetsById, previewTumbnailGeneratorUrl }: GridItemProps) {
  let cardImage = null;
  let headline = null;

  if (settings.gridItemImageColumn && (row.original.values[settings.gridItemImageColumn]?.value as any)?.asset_ids?.length) {
    const assetIds: string[] = (row.original.values[settings.gridItemImageColumn]?.value as any)?.asset_ids || [];
    const imageAssetId = assetIds.find((assetId) => {
      const asset = assetsById[assetId];

      if (!asset) {
        return false;
      }

      return IMAGE_EXTENSIONS.includes(asset.extension);
    });

    if (imageAssetId) {
      const asset = assetsById[imageAssetId];

      if (asset._thumbnails) {
        const thumbnailUrl =
          settings.gridItemImageColumnFormat && asset._thumbnails[settings.gridItemImageColumnFormat]
            ? asset._thumbnails[settings.gridItemImageColumnFormat]
            : asset._thumbnails[DEFAULT_IMAGE_FORMAT_ID];

        cardImage = (
          <div className="card-image" data-format={settings.gridItemImageColumnFormat}>
            <img src={`https://thumbnails.getgorilla.app${thumbnailUrl}`} width="" height="" alt="" />
          </div>
        );
      } else {
        cardImage = (
          <div className="card-image" data-format={settings.gridItemImageColumnFormat}>
            <img
              src={`${previewTumbnailGeneratorUrl}?mode=${settings.gridItemImageColumnFormat || DEFAULT_IMAGE_FORMAT_ID}&source=${encodeURIComponent(asset.public_url!)}`}
              width=""
              height=""
              alt=""
            />
          </div>
        );
      }
    }
  }

  if (settings.gridItemImageColumn && !cardImage) {
    cardImage = (
      <div className="SkeletonImage" data-format={settings.gridItemImageColumnFormat}>
        <div style={{ textAlign: 'center' }}>
          <i className="fa-solid fa-image"></i>
          <p style={{ marginTop: '20px', fontSize: '14px' }}>No image</p>
        </div>
      </div>
    );
  }

  if (settings.gridItemHeadlineColumn) {
    let headlineText = null;

    if (settings.gridItemHeadlineColumn === 'name') {
      headlineText = row.original.name;
    } else if ((row.original.values[settings.gridItemHeadlineColumn]?.value as any).text) {
      headlineText = (row.original.values[settings.gridItemHeadlineColumn]?.value as any).text;
    }

    if (headlineText) {
      headline = (
        <div className="card-headline">
          <h2>{headlineText}</h2>
        </div>
      );
    }
  }

  return (
    <div className="grid--item">
      {cardImage}
      <div className="card-content">
        {headline}
        {row.getVisibleCells().map((cell) => (
          <div key={cell.id} className="value-wrapper">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        ))}
      </div>
    </div>
  );
}

type GridProps = {
  mode: 'preview' | 'publish';
  settings: Settings;
  assetsById: AssetsById;
  columns: ColumnDef<ViewerBoardItem, string>[];
  previewTumbnailGeneratorUrl?: string;
  table: Table<ViewerBoardItem>;
};

export function Grid({ table, settings, assetsById, previewTumbnailGeneratorUrl, mode }: GridProps) {
  return (
    <div className={`grid content-alignment-${settings.textAlignment}`}>
      {table.getRowModel().rows.length === 0 ? (
        <div style={{ paddingBottom: '20px', textAlign: 'left' }}>No results found</div>
      ) : (
        <Masonry
          mode={settings.gridMode ? settings.gridMode : 'grid'}
          items={table.getRowModel().rows}
          config={{
            columns: [1, 2, 3, 4],
            media: [0, 600, 900, 1200],
          }}
          getWindow={() => {
            try {
              if (mode === 'preview') {
                return (window.document.querySelector('#viewer-iframe') as any).contentWindow;
              } else {
                return window;
              }
            } catch (e) {
              return null;
            }
          }}
          render={(row) => (
            <GridItem row={row} settings={settings} assetsById={assetsById} previewTumbnailGeneratorUrl={previewTumbnailGeneratorUrl} />
          )}
        />
      )}
    </div>
  );
}
