import { Flex, Button, Box, Modal, ModalHeader, ModalContent } from 'monday-ui-react-core';
// @ts-ignore-next-line
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
// @ts-ignore-next-line
import CompletedIcon from 'monday-ui-react-core/dist/icons/Completed';
// @ts-ignore-next-line
import FileIcon from 'monday-ui-react-core/dist/icons/File';

type ResetSettingsModalProps = {
  show: boolean;
  onClose: (reset: boolean) => void;
};

export function ResetSettingsModal({ show, onClose }: ResetSettingsModalProps) {
  return (
    <Modal
      contentSpacing
      alertDialog={true}
      data-testid="resetModal"
      width={Modal.width?.DEFAULT}
      show={show}
      onClose={() => {
        onClose(false);
      }}
    >
      <ModalHeader data-testid="resetModalHeader" title="Reset settings" description="" closeButtonAriaLabel="" />
      <ModalContent>
        <Box marginBottom={Box.marginBottoms?.LARGE}>
          <Flex gap={8} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
            If you reset the settings for this widget, all configurations are adjusted to their default values. This will not directly
            reflect on the published widget until you republish it.
          </Flex>
        </Box>

        <Box marginBottom={Box.marginBottoms?.MEDIUM}>
          <Flex gap={16}>
            <Button
              size={Button.sizes?.LARGE}
              kind={Button.kinds?.PRIMARY}
              color={Button.colors?.NEGATIVE}
              style={{
                width: '100%',
              }}
              onClick={() => {
                onClose(true);
              }}
            >
              Reset settings
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
}
