import React from 'react';
import Editor from './components/Editor';
import ErrorBox from './components/ErrorBox';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      return <ErrorBox message={(this.state.error as Error).message || 'Unknown error'} />;
    }

    return this.props.children;
  }
}

function App() {
  return (
    <div>
      <ErrorBoundary>
        <Editor />
      </ErrorBoundary>
    </div>
  );
}

export default App;
