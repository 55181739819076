import { useState, useEffect } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useDebounce } from '@uidotdev/usehooks';
import { Settings, ViewerBoardInfo } from '@gorilla/widgets-viewer/app/viewer/types';
import { getBoard } from '@gorilla/common/src/lib/monday-api/api';
import { getNormalizedBoardFiltersGraphQL } from '@gorilla/common/src/lib/rules';
import { useItemLoader } from './use-item-loader';
import { boardToBoardInfo, DEFAULT_SETTINGS } from '@gorilla/widgets-shared/src/services/publish';
import { monday, mondayClient } from '../services/monday';
import { getSettings, saveSettings, getExecutions } from '../services/settings';
import { getDemoSettings } from '../services/demo-settings';
import { getWidgetInfo, unpublishWidget } from '../services/widgets';

function cleanSettings(settings: Settings) {
  // remove keys that are not part of DEFAULT_SETTINGS
  const newSettings: Settings = {
    ...settings,
  };

  for (const key of Object.keys(newSettings)) {
    if (!(key in DEFAULT_SETTINGS)) {
      delete newSettings[key as keyof Settings];
    }
  }

  return newSettings;
}

type MondayContext = {
  boardId: number;
  user: {
    id: string;
  };
};

function findFirstFileFieldWithImages(boardInfo: ViewerBoardInfo) {
  const fileFields = boardInfo.fields.filter((field) => field.type === 'file');

  if (!fileFields.length) {
    return null;
  }

  return fileFields[0].id;
}

function getDefaultSettings(boardInfo: ViewerBoardInfo) {
  const settings: Settings = {
    ...DEFAULT_SETTINGS,
    gridItemImageColumn: findFirstFileFieldWithImages(boardInfo),
    gridItemHeadlineColumn: 'name',
  };

  return settings;
}

function fixSettings(settings: Settings, boardInfo: ViewerBoardInfo) {
  const newSettings: Settings = {
    ...settings,
  };

  if (newSettings.gridItemImageColumn && !boardInfo.fields.find((field) => field.id === newSettings.gridItemImageColumn)) {
    newSettings.gridItemImageColumn = findFirstFileFieldWithImages(boardInfo);
  }

  if (newSettings.gridItemHeadlineColumn && !boardInfo.fields.find((field) => field.id === newSettings.gridItemHeadlineColumn)) {
    newSettings.gridItemHeadlineColumn = 'name';
  }

  return cleanSettings(newSettings);
}

export function useEditor() {
  const [editorTab, setEditorTab] = useState<'preview' | 'published'>('preview');
  const [settingsDraft, setSettingsDraft] = useState<Settings | null>(null);
  const settingsDraftDeferred = useDebounce(settingsDraft, 200);

  const { data: mondayContext, error: mondayContextError } = useSWR(
    `/monday-context`,
    async () => {
      const mContext = ((await monday.get('context')) as any).data as MondayContext;

      if (!mContext) {
        throw new Error('This app can only be used inside of monday.com');
      }

      return mContext;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  const { data: board } = useSWR(
    mondayContext?.boardId ? `/board/${mondayContext?.boardId}` : null,
    async () => {
      const board = await getBoard(mondayClient, mondayContext!.boardId);
      return board;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  const {
    data: settingsServer,
    mutate: fetchServerSettings,
    error: settingsServerError,
  } = useSWR(
    board ? `/settings` : null,
    async () => {
      const boardInfo = boardToBoardInfo(board!);
      const result = await getSettings(`/${mondayContext!.boardId}`);
      const defaultSettings = getDefaultSettings(boardInfo);
      const demoSettings = getDemoSettings(boardInfo);

      return result ? fixSettings({ ...defaultSettings, ...result }, boardInfo) : demoSettings || defaultSettings;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  const path = mondayContext?.boardId ? `/${mondayContext?.boardId}` : null;

  const { data: widgetInfo, mutate: fetchWidgetInfo } = useSWR(
    path ? `/widget-info` : null,
    async () => {
      return await getWidgetInfo(`/${mondayContext!.boardId}`);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  const { data: executionsInfo } = useSWR(
    path ? `/executions` : null,
    async () => {
      return await getExecutions();
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  const { trigger: triggerSaveSettings, isMutating: isSavingSettings } = useSWRMutation('/save-settings', async () => {
    const path = `/${mondayContext?.boardId}`;
    const result = await saveSettings(path, settingsDraftDeferred);

    if (result.error) {
      await fetchServerSettings();
      setSettingsDraft(null);
    }
  });

  const { trigger: triggerUnpublish, isMutating: isUnpublishing } = useSWRMutation('/unpublish', async () => {
    setEditorTab('preview');
    const path = `/${mondayContext?.boardId}`;
    return unpublishWidget(path);
  });

  useEffect(() => {
    if (settingsDraftDeferred) {
      triggerSaveSettings();
    }
  }, [settingsDraftDeferred]);

  async function resetSettings() {
    const defaultSettings = getDefaultSettings(viewerData.boardInfo);
    const path = `/${mondayContext?.boardId}`;
    await saveSettings(path, defaultSettings);
    setSettingsDraft(null);
    fetchServerSettings();
  }

  const settings = settingsDraft || settingsServer;

  const { loadingMessage, data: viewerData } = useItemLoader(
    board && settings ? board.id : undefined,
    board && settings ? getNormalizedBoardFiltersGraphQL(board, settings?.boardFilters as any) : undefined,
  );

  function setSetting<K extends keyof Settings>(key: K, value: Settings[K]) {
    if (!settings) {
      return;
    }

    const newSettings: Settings = {
      ...settings,
      [key]: value,
    };

    setSettingsDraft(newSettings);
    setEditorTab('preview');
  }

  const isPublished = !!widgetInfo?.publishedAt;

  return {
    resetSettings,
    settings,
    setSetting,
    editorTab,
    setEditorTab,
    isSavingSettings,
    loadingMessage,
    viewerData,
    widgetInfo,
    fetchWidgetInfo,
    isPublished,
    triggerUnpublish,
    isUnpublishing,
    path,
    board,
    mondayContext,
    mondayContextError,
    settingsServerError,
    executionsInfo,
  };
}
