import asyncPool from 'tiny-async-pool';
import { Asset, Board } from '@gorilla/common/src/lib/monday-api/api';
import { EngineBoardItem } from '@gorilla/common/src/lib/engine/engine';
import {
  ViewerBoardField,
  ViewerBoardInfo,
  ViewerBoardItem,
  ViewerBoardItemValue,
  ViewerAsset,
  Settings,
} from '@gorilla/widgets-viewer/app/viewer/types';

export const DEFAULT_SETTINGS: Settings = {
  mode: 'table',

  // general settings
  bodyPadding: 'small',

  primaryColor: '#323338',
  secondaryColor: '#323338',
  borderColor: '#D0D4E4',
  backgroundColor: '#FFFFFF',

  fontFamily: 'Arial',
  fontSize: 14,
  textAlignment: 'left',

  sortColumn: 'name',
  sortDirection: 'asc',

  search: true,

  // table settings
  tableShowHeader: true,
  tableCellPadding: 'medium',
  tableBorderRadius: 'small',

  // grid settings
  gridMode: 'grid',
  gridItemPadding: 'medium',
  gridItemGap: 'medium',
  gridItemBorderRadius: 'medium',
  gridItemImageColumnFormat: null,
  gridItemImageColumn: null,
  gridItemHeadlineColumn: null,

  columnSettings: {},

  boardFilters: {},
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFields(board: Board): ViewerBoardField[] {
  const fields = board.columns
    .filter((column) => column.type !== 'subtasks')
    .map((column) => ({
      id: column.id,
      type: column.type,
      // @ts-ignore
      label: column.type === 'name' && board.item_terminology ? capitalizeFirstLetter(board.item_terminology) : column.title,
      settings: column.settings_str ? JSON.parse(column.settings_str) : {},
    })) as ViewerBoardField[];

  fields.splice(1, 0, {
    id: '__group__',
    type: 'group',
    label: 'Group',
    settings: {},
  });

  return fields;
}

export function boardToBoardInfo(board: Board) {
  return {
    name: board.name,
    description: board.description,
    fields: getFields(board),
  };
}

export function toViewerFormat(board: Board, itemsArg: EngineBoardItem[]): { boardInfo: ViewerBoardInfo; items: ViewerBoardItem[] } {
  const boardInfo = boardToBoardInfo(board);

  const items = itemsArg.map((item) => ({
    id: String(item.id),
    name: item.name,
    group: item.group,
    values: item.values.reduce(
      (acc, value) => {
        acc[value.column_id] = {
          id: value.column_id,
          value: value.value,
        };

        return acc;
      },
      {} as Record<string, ViewerBoardItemValue>,
    ),
  })) as any as ViewerBoardItem[];

  return { boardInfo, items };
}

async function handleFetchErrorResponse(message: string, res: Response) {
  if (!res.ok) {
    const error = new Error(message);
    // Attach extra info to the error object.
    try {
      (error as any).info = await res.text();
    } catch (err) {}

    (error as any).status = res.status;

    throw error;
  }
}

export async function addAssetToWidget(publisherEndpointUrl: string, sessionToken: string, path: string, name: string, url: string) {
  const response = await fetch(`${publisherEndpointUrl}/widget/add/file`, {
    method: 'POST',
    headers: {
      Authorization: `${sessionToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path, name, source: url }),
  });

  handleFetchErrorResponse('Failed to add Asset', response);

  return await response.text();
}

export async function addMetaFileToWidget(publisherEndpointUrl: string, sessionToken: string, path: string, type: string, data: any) {
  const response = await fetch(`${publisherEndpointUrl}/widget/add/${type}`, {
    method: 'POST',
    headers: {
      Authorization: `${sessionToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path, data }),
  });

  handleFetchErrorResponse('Failed to add file to widget', response);

  return await response.text();
}

export async function deleteWidgetAssets(publisherEndpointUrl: string, sessionToken: string, path: string, filePaths: string[]) {
  const response = await fetch(`${publisherEndpointUrl}/widget/remove/files`, {
    method: 'POST',
    headers: {
      Authorization: `${sessionToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path, filePaths }),
  });

  handleFetchErrorResponse('Failed to delete assets', response);

  return await response.text();
}

async function getPublishedAssets(publisherEndpointUrl: string, sessionToken: string, path: string) {
  const response = await fetch(`${publisherEndpointUrl}/widget/assets`, {
    method: 'POST',
    headers: {
      Authorization: `${sessionToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path }),
  });

  handleFetchErrorResponse('Failed to add Asset', response);

  return (await response.json()) as { assets: Array<{ path: string; size: number }> };
}

// Check if the assets are used in the items and settings and remove the unused assets
export function removeUnusedAssets(
  assets: ViewerAsset[] | Asset[],
  boardInfo: ViewerBoardInfo,
  items: ViewerBoardItem[],
  settings: Settings,
) {
  const visibleFileColumnIds = boardInfo.fields
    .filter((field) => field.type === 'file')
    .filter((field) => {
      const columnSettings = settings.columnSettings || {};
      const fieldSettings = columnSettings[field.id] || {};

      return !fieldSettings.hidden;
    })
    .map((field) => field.id);

  if (settings.mode === 'grid' && settings.gridItemImageColumn && !visibleFileColumnIds.includes(settings.gridItemImageColumn)) {
    visibleFileColumnIds.push(settings.gridItemImageColumn);
  }

  const usedAssetIds: Record<string, true> = {};

  for (const item of items) {
    for (const columnId of visibleFileColumnIds) {
      const value = item.values[columnId];

      if (value && value.value) {
        const v = value.value as any;
        const assetIds = v && v.asset_ids ? v.asset_ids : [];

        for (const assetId of assetIds) {
          usedAssetIds[assetId] = true;
        }
      }
    }
  }

  return assets.filter((asset) => usedAssetIds[asset.id]);
}

export async function upload(
  publisherEndpointUrl: string,
  sessionToken: string,
  path: string,
  settings: Settings,
  boardInfo: ViewerBoardInfo,
  items: ViewerBoardItem[],
  assets: Asset[],
  onProgress?: Function,
  abortSignal?: AbortSignal,
) {
  const log: { type: string; message: string }[] = [];

  const logMessage = (type: string, message: string) => {
    log.push({ type, message });
  };

  const publishedAssets = (await getPublishedAssets(publisherEndpointUrl, sessionToken, path)).assets;

  const publishedAssetsByPath = publishedAssets.reduce(
    (acc, asset) => {
      acc[asset.path] = true;
      return acc;
    },
    {} as Record<string, true>,
  );

  const uploadedAssetIds: string[] = [];

  const progressPerAsset = 95 / assets.length;
  let progress = 0;

  const jobs = assets.map((asset) => async () => {
    let assetPath = `assets/${asset.id}/${asset.name}`;

    if (assetPath in publishedAssetsByPath) {
      logMessage('info', `Asset ${asset.name} already uploaded`);
    } else {
      logMessage('info', `Uploading ${asset.name}...`);
      // TODO: check for asset size
      try {
        await addAssetToWidget(publisherEndpointUrl, sessionToken, path, assetPath, asset.public_url);
      } catch (err) {
        try {
          await addAssetToWidget(publisherEndpointUrl, sessionToken, path, assetPath, asset.public_url);
        } catch (err) {
          console.log('err', err);
          logMessage('warning', `Could not upload ${asset.name}`);
        }
      }
    }

    uploadedAssetIds.push(asset.id);

    progress += progressPerAsset;
    onProgress && onProgress(progress, log);
  });

  for await (const result of asyncPool(5, jobs, async (job) => {
    if (!abortSignal?.aborted) {
      await job();
    }
  })) {
    result;
  }

  if (!abortSignal?.aborted) {
    logMessage('info', 'Uploading board info...');
    try {
      await addMetaFileToWidget(publisherEndpointUrl, sessionToken, path, 'board', boardInfo);
    } catch (err) {
      logMessage('error', 'Failed to upload board info');
    }

    progress++;
    onProgress && onProgress(progress, log);
  }

  if (!abortSignal?.aborted) {
    logMessage('info', 'Uploading widget settings...');
    try {
      await addMetaFileToWidget(publisherEndpointUrl, sessionToken, path, 'settings', settings);
    } catch (err) {
      logMessage('error', 'Failed to upload widget settings');
    }

    progress++;
    onProgress && onProgress(progress, log);
  }

  if (!abortSignal?.aborted) {
    logMessage('info', 'Uploading board items...');
    try {
      await addMetaFileToWidget(publisherEndpointUrl, sessionToken, path, 'items', items);
    } catch (err) {
      logMessage('error', 'Failed to upload board items');
    }

    progress++;
    onProgress && onProgress(progress, log);
  }

  const assetsInfo = assets.map((asset) => ({
    id: asset.id,
    name: asset.name,
    extension: asset.file_extension,
    size: asset.file_size,
    uploaded: uploadedAssetIds.includes(asset.id),
  }));

  if (!abortSignal?.aborted) {
    logMessage('info', 'Uploading assets...');
    try {
      await addMetaFileToWidget(publisherEndpointUrl, sessionToken, path, 'assets', assetsInfo);
    } catch (err) {
      logMessage('error', 'Failed to upload assets');
    }

    progress++;
    onProgress && onProgress(progress, log);
  }

  const assetsByPath = assets.reduce(
    (acc, asset) => {
      let assetPath = `assets/${asset.id}/${asset.name}`;
      acc[assetPath] = asset;
      return acc;
    },
    {} as Record<string, Asset>,
  );

  const assetsToDelete = publishedAssets.filter((asset) => !assetsByPath[asset.path]);

  if (!abortSignal?.aborted) {
    if (assetsToDelete.length > 0) {
      logMessage('info', 'Deleting unused assets...');
      try {
        await deleteWidgetAssets(
          publisherEndpointUrl,
          sessionToken,
          path,
          assetsToDelete.map((asset) => asset.path),
        );
      } catch (err) {
        logMessage('info', 'Failed to delete unused assets');
      }
    }

    progress++;
    onProgress && onProgress(progress, log);
  }

  return log;
}
