import { useState } from 'react';
import _ from 'lodash';
import { Flex, IconButton } from 'monday-ui-react-core';
import { SelectSetting } from './SelectSetting';
import { ToggleSetting } from './ToggleSetting';
import { ColorSetting } from './ColorSetting';
import { SidebarWidget } from './SidebarWidget';
import { ColumnSettingsModal } from './ColumnSettingsModal';
import { PADDING_SIZES, PADDING_SIZES_WITH_NONE, FONTS, FONT_SIZES, TEXT_ALIGNMENTS } from '../constants';
import { Icon } from 'monday-ui-react-core';
import { Settings as SettingsType, ViewerBoardInfo } from '@gorilla/widgets-viewer/app/viewer/types';
import { IMAGE_FORMATS, DEFAULT_IMAGE_FORMAT_ID } from '@gorilla/widgets-shared/src/constants';

// @ts-ignore-next-line
import TableIcon from 'monday-ui-react-core/dist/icons/Table';
// @ts-ignore-next-line
import WorkspaceIcon from 'monday-ui-react-core/dist/icons/Workspace';
// @ts-ignore-next-line
import HideIcon from 'monday-ui-react-core/dist/icons/Hide';
// @ts-ignore-next-line
import ShowIcon from 'monday-ui-react-core/dist/icons/Show';
// @ts-ignore-next-line
import SettingsIcon from 'monday-ui-react-core/dist/icons/Settings';
// @ts-ignore-next-line
import LayoutIcon from 'monday-ui-react-core/dist/icons/Layout';
// @ts-ignore-next-line
import ColumnIcon from 'monday-ui-react-core/dist/icons/Column';
// @ts-ignore-next-line
import ImageIcon from 'monday-ui-react-core/dist/icons/Image';

type SettingProps = {
  settings: SettingsType;
  setSetting: <K extends keyof SettingsType>(key: K, value: SettingsType[K]) => void;
  boardInfo: ViewerBoardInfo;
  setHelpState: any;
  settingCategory: string;
  setShowHelpModal: any;
};

function BodyPadding({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Widget margin"
      options={PADDING_SIZES_WITH_NONE}
      value={settings.bodyPadding}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('bodyPadding', newValue as SettingsType['bodyPadding']);
      }}
    />
  );
}

function PrimaryColor({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <ColorSetting
      title="Primary color"
      value={settings.primaryColor}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('primaryColor', newValue);
      }}
    />
  );
}

function SecondaryColor({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <ColorSetting
      title="Secondary color"
      value={settings.secondaryColor}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('secondaryColor', newValue);
      }}
    />
  );
}

function BorderColor({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <ColorSetting
      title="Border color"
      value={settings.borderColor}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('borderColor', newValue);
      }}
    />
  );
}

function BackgroundColor({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <ColorSetting
      title="Background color"
      value={settings.backgroundColor}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('backgroundColor', newValue);
      }}
    />
  );
}

function FontFamily({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Font family"
      options={FONTS}
      value={settings.fontFamily}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('fontFamily', newValue as SettingsType['fontFamily']);
      }}
    />
  );
}

function FontSize({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Font size"
      options={FONT_SIZES}
      value={settings.fontSize}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('fontSize', newValue as SettingsType['fontSize']);
      }}
    />
  );
}

function TextAlignment({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Text alignment"
      options={TEXT_ALIGNMENTS}
      value={settings.textAlignment}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('textAlignment', newValue as SettingsType['textAlignment']);
      }}
    />
  );
}

function SortColumn({ settings, setSetting, boardInfo, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  const options = boardInfo.fields.map((field) => ({ label: field.label, value: field.id, leftIcon: ColumnIcon }));

  return (
    <SelectSetting
      title="Sort column"
      options={options}
      value={settings.sortColumn}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('sortColumn', newValue as SettingsType['sortColumn']);
      }}
    />
  );
}

function SortDirection({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  const options = [
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' },
  ];

  return (
    <SelectSetting
      title="Sort Direction"
      options={options}
      value={settings.sortDirection}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('sortDirection', newValue as SettingsType['sortDirection']);
      }}
    />
  );
}

function Search({ settings, setSetting }: SettingProps) {
  return (
    <ToggleSetting
      title="Show search"
      description="Disable search"
      value={settings.search}
      onChange={(newValue) => {
        setSetting('search', newValue);
      }}
    />
  );
}

function TableShowHeader({ settings, setSetting }: SettingProps) {
  return (
    <ToggleSetting
      title="Show table header"
      description="Show table header"
      value={settings.tableShowHeader}
      onChange={(newValue) => {
        setSetting('tableShowHeader', newValue);
      }}
    />
  );
}

function TableCellPadding({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Cell margin"
      options={PADDING_SIZES}
      value={settings.tableCellPadding}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('tableCellPadding', newValue as SettingsType['tableCellPadding']);
      }}
    />
  );
}

function TableBorderRadius({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Table radius"
      options={PADDING_SIZES_WITH_NONE}
      value={settings.tableBorderRadius}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('tableBorderRadius', newValue as SettingsType['tableBorderRadius']);
      }}
    />
  );
}

function GridMode({ settings, setSetting }: SettingProps) {
  return (
    <ToggleSetting
      title="Enable item auto height"
      description="Items have dynamic height (masonry mode)"
      value={settings.gridMode === 'masonry'}
      onChange={(newValue) => {
        setSetting('gridMode', newValue ? 'masonry' : 'grid');
      }}
    />
  );
}

function GridItemPadding({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Item margin"
      options={PADDING_SIZES_WITH_NONE}
      value={settings.gridItemPadding}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('gridItemPadding', newValue as SettingsType['gridItemPadding']);
      }}
    />
  );
}

function GridItemGap({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Item gap"
      options={PADDING_SIZES}
      value={settings.gridItemGap}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('gridItemGap', newValue as SettingsType['gridItemGap']);
      }}
    />
  );
}

function GridItemBorderRadius({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  return (
    <SelectSetting
      title="Item radius"
      options={PADDING_SIZES_WITH_NONE}
      value={settings.gridItemBorderRadius}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('gridItemBorderRadius', newValue as SettingsType['gridItemBorderRadius']);
      }}
    />
  );
}

function GridItemImageColumn({ settings, setSetting, boardInfo, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  const options = boardInfo.fields
    .filter((field) => field.type === 'file')
    .map((field) => ({ label: field.label, value: field.id, leftIcon: ColumnIcon }));

  options.unshift({ label: 'None', value: '__none__', leftIcon: HideIcon });

  return (
    <SelectSetting
      title="Item image column"
      options={options}
      value={!settings.gridItemImageColumn ? '__none__' : settings.gridItemImageColumn}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        if (newValue === '__none__') {
          setSetting('gridItemImageColumn', null);
        } else {
          setSetting('gridItemImageColumn', newValue as SettingsType['gridItemImageColumn']);
        }
      }}
    />
  );
}

function GridItemImageColumnFormat({ settings, setSetting, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  const options = IMAGE_FORMATS.filter(({ active }) => active).map((format) => ({
    label: format.label,
    value: format.id,
    leftIcon: ImageIcon,
  }));
  const value = settings.gridItemImageColumnFormat || DEFAULT_IMAGE_FORMAT_ID;

  return (
    <SelectSetting
      title="Item image format"
      options={options}
      value={value}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        setSetting('gridItemImageColumnFormat', newValue as SettingsType['gridItemImageColumnFormat']);
      }}
    />
  );
}

function GridItemHeadlineColumn({ settings, setSetting, boardInfo, setHelpState, settingCategory, setShowHelpModal }: SettingProps) {
  const options = boardInfo.fields
    .filter((field) => field.type === 'text' || field.type === 'name')
    .map((field) => ({ label: field.label, value: field.id, leftIcon: ColumnIcon }));

  options.unshift({ label: 'None', value: '__none__', leftIcon: HideIcon });

  return (
    <SelectSetting
      title="Item headline column"
      options={options}
      value={!settings.gridItemHeadlineColumn ? '__none__' : settings.gridItemHeadlineColumn}
      setHelpState={setHelpState}
      settingCategory={settingCategory}
      setShowHelpModal={setShowHelpModal}
      onChange={(newValue) => {
        if (newValue === '__none__') {
          setSetting('gridItemHeadlineColumn', null);
        } else {
          setSetting('gridItemHeadlineColumn', newValue as SettingsType['gridItemHeadlineColumn']);
        }
      }}
    />
  );
}
const SETTING_TO_COMPONENT: Record<
  keyof Omit<SettingsType, 'mode' | 'columnSettings' | 'boardFilters'>,
  (props: SettingProps) => JSX.Element
> = {
  bodyPadding: BodyPadding,
  primaryColor: PrimaryColor,
  secondaryColor: SecondaryColor,
  borderColor: BorderColor,
  backgroundColor: BackgroundColor,
  fontFamily: FontFamily,
  fontSize: FontSize,
  textAlignment: TextAlignment,
  sortColumn: SortColumn,
  sortDirection: SortDirection,
  search: Search,
  tableShowHeader: TableShowHeader,
  tableCellPadding: TableCellPadding,
  tableBorderRadius: TableBorderRadius,
  gridMode: GridMode,
  gridItemPadding: GridItemPadding,
  gridItemGap: GridItemGap,
  gridItemBorderRadius: GridItemBorderRadius,
  gridItemImageColumn: GridItemImageColumn,
  gridItemImageColumnFormat: GridItemImageColumnFormat,
  gridItemHeadlineColumn: GridItemHeadlineColumn,
};

type SettingsBuilderProps = {
  settingKeys: (keyof typeof SETTING_TO_COMPONENT)[];
  settings: SettingsType;
  setSetting: <K extends keyof SettingsType>(key: K, value: SettingsType[K]) => void;
  boardInfo: ViewerBoardInfo;
  setHelpState: any;
  settingCategory: string;
  setShowHelpModal: any;
};

function SettingsBuilder({
  settingKeys,
  settings,
  setSetting,
  boardInfo,
  setHelpState,
  settingCategory,
  setShowHelpModal,
}: SettingsBuilderProps) {
  return (
    <>
      {settingKeys.map((key) => {
        if (key in SETTING_TO_COMPONENT) {
          const Component = SETTING_TO_COMPONENT[key];
          return (
            <Component
              key={key}
              settings={settings}
              setSetting={setSetting}
              boardInfo={boardInfo}
              setHelpState={setHelpState}
              settingCategory={settingCategory}
              setShowHelpModal={setShowHelpModal}
            />
          );
        }
      })}
    </>
  );
}

type GeneralSettingsProps = {
  settingKeys?: (keyof typeof SETTING_TO_COMPONENT)[];
  settings: SettingsType;
  setSetting: <K extends keyof SettingsType>(key: K, value: SettingsType[K]) => void;
  boardInfo: ViewerBoardInfo;
  setHelpState: any;
  settingCategory: string;
  setShowHelpModal: any;
};

function GeneralSettings({
  settings,
  setSetting,
  boardInfo,
  settingKeys = [
    'bodyPadding',
    'primaryColor',
    'secondaryColor',
    'borderColor',
    'backgroundColor',
    'fontFamily',
    'fontSize',
    'textAlignment',
    'sortColumn',
    'sortDirection',
  ],
  setHelpState,
  setShowHelpModal,
}: GeneralSettingsProps) {
  return (
    <SidebarWidget
      collapsed
      title="General settings"
      description="Define the outer margin, choose colors and adapt font settings on your widget."
      icon={SettingsIcon}
    >
      <SettingsBuilder
        settingKeys={settingKeys}
        settings={settings}
        setSetting={setSetting}
        boardInfo={boardInfo}
        setHelpState={setHelpState}
        settingCategory="general_settings"
        setShowHelpModal={setShowHelpModal}
      />
    </SidebarWidget>
  );
}

export function ColumnSettings({ settings, setSetting, boardInfo }: GeneralSettingsProps) {
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);
  const selectedField = selectedFieldId ? boardInfo.fields.find((field) => field.id === selectedFieldId) : null;

  return (
    <>
      {selectedField ? (
        <ColumnSettingsModal
          show
          field={selectedField}
          columnSettings={settings?.columnSettings?.[selectedField.id] || {}}
          onClose={(newColumnSettings) => {
            if (newColumnSettings) {
              const columnSettings = settings?.columnSettings || {};

              setSetting('columnSettings', {
                ...columnSettings,
                [selectedField.id]: newColumnSettings,
              });
            }
            setSelectedFieldId(null);
          }}
        />
      ) : null}

      <SidebarWidget
        collapsed
        title="Column settings"
        description="Hide specific board columns in your widget and apply advanced settings."
        icon={ColumnIcon}
      >
        {boardInfo.fields.map((field) => {
          const isHidden = _.get(settings, ['columnSettings', field.id, 'hidden']) ? true : false;

          return (
            <Flex key={field.id} gap={10}>
              <IconButton
                icon={isHidden ? HideIcon : ShowIcon}
                kind={isHidden ? IconButton.kinds.SECONDARY : IconButton.kinds.PRIMARY}
                size={IconButton.sizes.XS}
                onClick={() => {
                  const columnSettings = settings?.columnSettings || {};

                  setSetting('columnSettings', {
                    ...columnSettings,
                    [field.id]: {
                      ...(field.id in columnSettings ? columnSettings[field.id] : {}),
                      hidden: !isHidden,
                    },
                  });
                }}
              />
              <div
                style={{
                  overflow: 'hidden',
                  width: '184px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {field.label}
              </div>
              <IconButton
                icon={SettingsIcon}
                //kind={hasSetting ? IconButton.kinds.TERTIARY : IconButton.kinds.SECONDARY}
                kind={IconButton.kinds.TERTIARY}
                size={IconButton.sizes.XS}
                onClick={() => {
                  setSelectedFieldId(field.id);
                }}
              />
            </Flex>
          );
        })}
      </SidebarWidget>
    </>
  );
}

type ModeSettingsProps = {
  settings: SettingsType;
  setSetting: <K extends keyof SettingsType>(key: K, value: SettingsType[K]) => void;
  boardInfo: ViewerBoardInfo;
  setHelpState: any;
  settingCategory: string;
  setShowHelpModal: any;
};

function TableModeSettings({ settings, setSetting, boardInfo, setHelpState, setShowHelpModal }: ModeSettingsProps) {
  return (
    <>
      <SidebarWidget
        collapsed
        title="Table settings"
        description="Define table layout specific settings and show or hide control elements."
        icon={TableIcon}
      >
        <SettingsBuilder
          settingKeys={['search', 'tableShowHeader', 'tableCellPadding', 'tableBorderRadius']}
          settings={settings}
          setSetting={setSetting}
          boardInfo={boardInfo}
          setHelpState={setHelpState}
          settingCategory="table_settings"
          setShowHelpModal={setShowHelpModal}
        />
      </SidebarWidget>
      <GeneralSettings
        settings={settings}
        setSetting={setSetting}
        boardInfo={boardInfo}
        setHelpState={setHelpState}
        settingCategory="general_settings"
        setShowHelpModal={setShowHelpModal}
      />
    </>
  );
}

function GridModeSettings({ settings, setSetting, boardInfo, setHelpState, setShowHelpModal }: ModeSettingsProps) {
  return (
    <>
      <SidebarWidget
        collapsed
        title="Grid settings"
        description="Define grid layout specific settings, choose the main image and headline."
        icon={WorkspaceIcon}
      >
        <SettingsBuilder
          settingKeys={[
            'search',
            'gridMode',
            'gridItemPadding',
            'gridItemGap',
            'gridItemBorderRadius',
            'gridItemImageColumn',
            'gridItemImageColumnFormat',
            'gridItemHeadlineColumn',
          ]}
          settings={settings}
          setSetting={setSetting}
          boardInfo={boardInfo}
          setHelpState={setHelpState}
          settingCategory="grid_settings"
          setShowHelpModal={setShowHelpModal}
        />
      </SidebarWidget>
      <GeneralSettings
        settings={settings}
        setSetting={setSetting}
        boardInfo={boardInfo}
        setHelpState={setHelpState}
        settingCategory="grid_settings"
        setShowHelpModal={setShowHelpModal}
      />
    </>
  );
}

export const MODE_TO_SETTINGS: Record<SettingsType['mode'], (props: ModeSettingsProps) => JSX.Element> = {
  table: TableModeSettings,
  grid: GridModeSettings,
};

export const MODES: { label: string; value: SettingsType['mode']; icon: any }[] = [
  { label: 'Table', value: 'table', icon: TableIcon },
  { label: 'Grid', value: 'grid', icon: WorkspaceIcon },
  //{ label: 'FAQ', value: 'accordion', icon: StatusIcon },
];

export function LayoutsSettings({ settings, setSetting }: SettingProps) {
  return (
    <SidebarWidget title="Layout" description="Select a widget layout that you want to use to display your board items." icon={LayoutIcon}>
      <div className="WidgetTypeWrapper">
        {MODES.map((mode) => (
          <div
            key={mode.value}
            className={settings.mode === mode.value ? 'WidgetType WidgetTypeActive' : 'WidgetType'}
            onClick={() => {
              setSetting('mode', mode.value);
            }}
          >
            <div className="WidgetTypeInner">
              <Icon icon={mode.icon} iconSize={20} clickable={false} />
              <h5>{mode.label}</h5>
            </div>
          </div>
        ))}
      </div>
    </SidebarWidget>
  );
}
