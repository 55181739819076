function getRootEl() {
  const iframeEl = document.querySelector('#viewer-iframe') as HTMLIFrameElement;

  if (iframeEl) {
    return iframeEl.contentDocument?.querySelector(':root') as HTMLElement;
  }

  return document.querySelector(':root') as HTMLElement;
}

export function useViewer() {
  function setCSSVariables(variables: Record<string, string>) {
    const rootEl = getRootEl();

    Object.entries(variables).forEach(([key, value]) => {
      rootEl.style.setProperty(`--${key}`, value);
    });
  }

  function getCSSVariable(variableKey: string) {
    const rootEl = getRootEl();
    return getComputedStyle(rootEl).getPropertyValue(`--${variableKey}`);
  }

  return {
    setCSSVariables,
    getCSSVariable,
  };
}
