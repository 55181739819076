import { getSessionToken } from './monday';
import { Settings } from '@gorilla/widgets-viewer/app/viewer/types';

async function getAuthHeaders() {
  const sessionToken = await getSessionToken();

  return {
    Authorization: `${sessionToken}`,
  };
}

export async function getSettings(path: string) {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/settings/get`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path }),
  });

  if (response.status === 404) {
    return null;
  }

  if (response.status === 200) {
    return (await response.json()).data as Settings;
  }

  const errContent = await response.json();
  throw new Error(errContent?.error ? errContent?.error : 'Unknown error');
}

export async function saveSettings(path: string, settings: any) {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/settings`, {
    method: 'PUT',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ path, data: settings }),
  });

  if (response.status === 404) {
    return null;
  }

  return await response.json();
}

export async function getExecutions() {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/executions`, {
    method: 'GET',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
  });

  return (await response.json()) as { executionsCount: number; executionsLimit: number };
}
