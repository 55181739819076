import * as _ from 'lodash';
import { useState } from 'react';
import { Settings, ViewerBoardField } from '@gorilla/widgets-viewer/app/viewer/types';
import { ToggleSetting } from './ToggleSetting';
import { DATE_FORMATS, TIME_FORMATS } from '../constants';
import { Text, Dropdown, Flex, Button, Box, Modal, ModalHeader, ModalContent } from 'monday-ui-react-core';
// @ts-ignore-next-line
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
// @ts-ignore-next-line
import CompletedIcon from 'monday-ui-react-core/dist/icons/Completed';
// @ts-ignore-next-line
import FileIcon from 'monday-ui-react-core/dist/icons/File';

type ColumnSettings = Settings['columnSettings'][string];

type ColumnSettingsModalProps = {
  show: boolean;
  columnSettings: ColumnSettings;
  field: ViewerBoardField;
  onClose: (columnSettings?: ColumnSettings) => void;
};

export function ColumnSettingsModal({ show, field, onClose, columnSettings = {} }: ColumnSettingsModalProps) {
  const [draft, setDraft] = useState(_.cloneDeep(columnSettings));
  let selectedDateFormatOption = DATE_FORMATS[0];

  if (draft.dateFormat && DATE_FORMATS.find((option) => option.value === draft.dateFormat)) {
    selectedDateFormatOption = DATE_FORMATS.find((option) => option.value === draft.dateFormat)!;
  }

  let selectedTimeFormatOption = TIME_FORMATS[0];

  if (draft.timeFormat && TIME_FORMATS.find((option) => option.value === draft.timeFormat)) {
    selectedTimeFormatOption = TIME_FORMATS.find((option) => option.value === draft.timeFormat)!;
  }

  return (
    <Modal
      contentSpacing
      alertDialog={true}
      data-testid="columnSettingsModal"
      width={Modal.width?.DEFAULT}
      show={show}
      onClose={() => {
        onClose();
      }}
    >
      <ModalHeader data-testid="columnSettingsModalHeader" title={`${field.label} Settings`} description="" closeButtonAriaLabel="" />
      <ModalContent>
        <Box marginBottom={Box.marginBottoms?.LARGE}>
          <Flex gap={8} direction={Flex.directions?.COLUMN} align={Flex.align?.START}>
            <Box>
              <div style={{ marginBottom: '8px' }}>
                <ToggleSetting
                  title="Show label"
                  value={!!draft.showLabel}
                  onChange={(value) => {
                    setDraft({
                      ...draft,
                      showLabel: value,
                    });
                  }}
                />
              </div>
              <div style={{ marginBottom: '8px' }}>
                <ToggleSetting
                  title="Display label inline"
                  value={draft.labelMode === 'inline'}
                  onChange={(value) => {
                    setDraft({
                      ...draft,
                      labelMode: value ? 'inline' : 'above',
                    });
                  }}
                />
              </div>
              {field.type === 'file' ? (
                <div style={{ marginBottom: '8px' }}>
                  <ToggleSetting
                    title="Display thumbnails"
                    value={draft.filesDisplayMode === 'thumbs'}
                    onChange={(value) => {
                      setDraft({
                        ...draft,
                        filesDisplayMode: value ? 'thumbs' : 'list',
                      });
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {field.type === 'mirror' ? (
                <div style={{ marginBottom: '8px' }}>
                  <ToggleSetting
                    title="Summarize Numbers"
                    value={!!draft.summarizeNumbers}
                    onChange={(value) => {
                      setDraft({
                        ...draft,
                        summarizeNumbers: value,
                      });
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {field.type === 'date' ? (
                <>
                  <div style={{ marginTop: '24px', marginLeft: '4px' }}>
                    <Text type={Text.types?.TEXT1} style={{ marginBottom: '4px' }}>
                      Choose date format
                    </Text>
                    <Dropdown
                      menuPosition="fixed"
                      onChange={(newOption: any) => {
                        setDraft({
                          ...draft,
                          dateFormat: newOption.value,
                        });
                      }}
                      size={Dropdown.sizes?.MEDIUM}
                      options={DATE_FORMATS}
                      value={selectedDateFormatOption}
                      clearable={false}
                    />
                  </div>
                  <div style={{ marginTop: '24px', marginLeft: '4px' }}>
                    <Text type={Text.types?.TEXT1} style={{ marginBottom: '4px' }}>
                      Choose time format
                    </Text>
                    <Dropdown
                      menuPosition="fixed"
                      onChange={(newOption: any) => {
                        setDraft({
                          ...draft,
                          timeFormat: newOption.value,
                        });
                      }}
                      size={Dropdown.sizes?.MEDIUM}
                      options={TIME_FORMATS}
                      value={selectedTimeFormatOption}
                      clearable={false}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Flex>
        </Box>
        <Box marginBottom={Box.marginBottoms?.MEDIUM}>
          <Flex gap={16}>
            <Button
              size={Button.sizes?.LARGE}
              kind={Button.kinds?.PRIMARY}
              color={Button.colors?.PRIMARY}
              style={{
                width: '100%',
              }}
              onClick={() => {
                onClose(draft);
              }}
            >
              Apply
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
}
