import classNames from 'classnames';

type PaginationProps = {
  currentPage: number;
  pageCount: number;
  onPageClick: (pageIndex: number) => void;
};

export function Pagination({ currentPage, pageCount, onPageClick }: PaginationProps) {
  const pages = [];
  let startPage = Math.max(currentPage - 2, 0);
  let endPage = Math.min(startPage + 4, pageCount - 1);

  if (endPage - startPage < 4) {
    startPage = Math.max(endPage - 4, 0);
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <button
        key={i}
        className={classNames('PaginationButton', { 'PaginationButton--Active': i === currentPage })}
        onClick={() => onPageClick(i)}
      >
        {i + 1}
      </button>,
    );
  }

  return <>{pages}</>;
}
