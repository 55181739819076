import { useState } from 'react';
import _ from 'lodash';
import { Flex, Button, Box, Divider, Loader, Icon, Tooltip } from 'monday-ui-react-core';
import { formatDistanceToNowStrict } from 'date-fns';
import Frame from 'react-frame-component';
import { useEditor } from '../hooks/use-editor';
import { PublishModal } from './PublishModal';
import { ResetSettingsModal } from './ResetSettingsModal';
import { HelpModal } from './HelpModal';
import { DeleteWidgetModal } from './DeleteWidgetModal';
import { Viewer } from '@gorilla/widgets-viewer/app/viewer/Viewer';
import viewerStyles from '@gorilla/widgets-viewer/app/viewer/viewer.css?inline';
import { BoardRulesModal } from '@gorilla/ui/src/components/BoardRulesModal';
import { hasBoardFilters, areBoardFiltersValid } from '@gorilla/common/src/lib/rules';
import { MODE_TO_SETTINGS, LayoutsSettings, ColumnSettings } from './Settings';
import ErrorBox from './ErrorBox';
import { PublishedIframe } from './PublishedIframe';
import './editor.css';

// @ts-ignore-next-line
import BulletIcon from 'monday-ui-react-core/dist/icons/Bullet';

// @ts-ignore-next-line
import ExternalPageIcon from 'monday-ui-react-core/dist/icons/ExternalPage';

// @ts-ignore-next-line
import LearnMoreIcon from 'monday-ui-react-core/dist/icons/LearnMore';

// @ts-ignore-next-line
import FilterIcon from 'monday-ui-react-core/dist/icons/Filter';

// @ts-ignore-next-line
import RetryIcon from 'monday-ui-react-core/dist/icons/Retry';

// @ts-ignore-next-line
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';

// @ts-ignore-next-line
import RobotIcon from 'monday-ui-react-core/dist/icons/Robot';

// @ts-ignore-next-line
import UploadIcon from 'monday-ui-react-core/dist/icons/Upload';

// @ts-ignore-next-line
import EventIcon from 'monday-ui-react-core/dist/icons/Event';

// @ts-ignore-next-line
import CompletedIcon from 'monday-ui-react-core/dist/icons/Completed';

// @ts-ignore-next-line
import ActivityIcon from 'monday-ui-react-core/dist/icons/Activity';

function Editor() {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [helpState, setHelpState] = useState({ category: '', content: '' });

  const {
    settings,
    setSetting,
    editorTab,
    setEditorTab,
    loadingMessage,
    viewerData,
    path,
    widgetInfo,
    fetchWidgetInfo,
    isPublished,
    triggerUnpublish,
    isUnpublishing,
    mondayContext,
    mondayContextError,
    settingsServerError,
    resetSettings,
    board,
    executionsInfo,
  } = useEditor();

  if (_.get(mondayContext, 'user.isGuest') === true) {
    return <ErrorBox message="Guests are not allowed to use this app" />;
  }

  if (_.get(mondayContext, 'user.isViewOnly') === true) {
    return <ErrorBox message="Viewers are not allowed to use this app" />;
  }

  if (mondayContextError) {
    return <ErrorBox message={mondayContextError?.message} />;
  }

  if (settingsServerError) {
    return <ErrorBox message={settingsServerError?.message} />;
  }

  if (!viewerData || !board) {
    return (
      <div
        style={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <Flex gap={12}>
          <Loader size={24} color={Loader.colors.PRIMARY} />
          <div>{loadingMessage}</div>
        </Flex>
      </div>
    );
  }

  if (!settings) {
    return null;
  }

  let ModeSettingsComponent = null;

  if (settings.mode in MODE_TO_SETTINGS) {
    ModeSettingsComponent = MODE_TO_SETTINGS[settings.mode];
  }

  return (
    <>
      {showHelpModal ? (
        <HelpModal
          show={showHelpModal}
          category={helpState.category}
          content={helpState.content}
          onClose={() => {
            setShowHelpModal(false);
            setHelpState({ category: '', content: '' });
          }}
        />
      ) : null}

      {showResetModal ? (
        <ResetSettingsModal
          show={showResetModal}
          onClose={(reset) => {
            if (reset) {
              resetSettings();
            }
            setShowResetModal(false);
          }}
        />
      ) : null}

      {showDeleteModal ? (
        <DeleteWidgetModal
          show={showDeleteModal}
          isUnpublishing={isUnpublishing}
          onClose={async (doDelete) => {
            if (doDelete) {
              await triggerUnpublish();
              fetchWidgetInfo();
            }

            setShowDeleteModal(false);
          }}
        />
      ) : null}

      {/* PUBLISH MODAL */}

      {showPublishModal && path ? (
        <PublishModal
          show={showPublishModal}
          boardInfo={viewerData.boardInfo}
          items={viewerData.items}
          assets={viewerData.assets}
          path={path}
          settings={settings}
          isPublished={isPublished}
          widgetInfo={widgetInfo}
          onClose={() => {
            fetchWidgetInfo();
            setShowPublishModal(false);
          }}
        />
      ) : null}

      <div className="Datagrid">
        <div className="Bar">
          <Flex gap={16} align={Flex.align?.CENTER} justify={Flex.justify?.START}>
            <Flex gap={24} style={{ fontSize: '13px', lineHeight: '18px' }}>
              <div style={{ width: '100px' }}>
                <Flex gap={10}>
                  {/*<Icon iconType={Icon.type.SVG} icon={ActivityIcon} iconSize={30} clickable={false} style={{ transform: 'translate3d(0,-1px,0)' }} />*/}
                  <Box>
                    <Flex gap={4} align={Flex.align.CENTER}>
                      <strong style={{ fontWeight: 600 }}>Widget status</strong>
                    </Flex>
                    <span className="Bar--Value">
                      {widgetInfo?.publishedAt ? 'Published' : 'Unpublished'}{' '}
                      <span className={widgetInfo?.publishedAt ? 'Bar--Indicator Published' : 'Bar--Indicator Draft'}>
                        <Icon iconType={Icon.type.SVG} icon={BulletIcon} iconSize={16} clickable={false} />
                      </span>
                    </span>
                  </Box>
                </Flex>
              </div>
              <div style={{ height: '68px' }}>
                <Divider direction={Divider.directions?.VERTICAL} withoutMargin />
              </div>
              {widgetInfo?.publishedAt ? (
                <>
                  <div style={{ width: '100px' }}>
                    <Flex gap={10}>
                      {/*<Icon iconType={Icon.type.SVG} icon={CompletedIcon} iconSize={30} clickable={false} style={{ transform: 'translate3d(0,-1px,0)' }} />*/}
                      <Box>
                        <Flex gap={4} align={Flex.align.CENTER}>
                          <strong style={{ fontWeight: 600 }}>Last published</strong>
                        </Flex>
                        <span className="Bar--Value">
                          {widgetInfo?.publishedAt ? `${formatDistanceToNowStrict(new Date(widgetInfo.publishedAt))} ago` : ''}
                        </span>
                      </Box>
                    </Flex>
                  </div>
                  <div style={{ height: '68px' }}>
                    <Divider direction={Divider.directions?.VERTICAL} withoutMargin />
                  </div>
                </>
              ) : null}
              <div style={{ width: '100px' }}>
                <Flex gap={10}>
                  {/*<Icon iconType={Icon.type.SVG} icon={RobotIcon} iconSize={38} clickable={false} style={{ transform: 'translate3d(0,-2px,0)' }} />*/}
                  <Box>
                    <Flex gap={4} align={Flex.align.CENTER}>
                      <strong style={{ fontWeight: 600 }}>Automations left</strong>
                    </Flex>
                    <span className="Bar--Value">
                      {executionsInfo ? `${executionsInfo.executionsLimit - executionsInfo.executionsCount} this month` : 'N/A'}{' '}
                    </span>
                  </Box>
                </Flex>
              </div>
              <div style={{ height: '68px' }}>
                <Divider direction={Divider.directions?.VERTICAL} withoutMargin />
              </div>
            </Flex>
            <div style={{ flex: 1 }}>
              <Flex justify={Flex.justify.CENTER}></Flex>
            </div>
            <Box marginStart={Box.marginStarts?.AUTO}>
              <Flex gap={12}>
                {/* 
                {hasBoardFilters(board, settings.boardFilters as any) ? (
                  <>
                    <Button
                      leftIcon={FilterIcon}
                      kind={Button.kinds?.TERTIARY}
                      size={Button.sizes?.MEDIUM}
                      color={areBoardFiltersValid(board, settings.boardFilters as any) ? Button.colors.PRIMARY : Button.colors.NEGATIVE}
                      onClick={() => {
                        setShowFilters((prevVal) => !prevVal);
                      }}
                    >
                      Edit filter
                    </Button>
                  </>
                ) : (
                  <Button
                    leftIcon={FilterIcon}
                    kind={Button.kinds?.TERTIARY}
                    size={Button.sizes?.MEDIUM}
                    color={areBoardFiltersValid(board, settings.boardFilters as any) ? Button.colors.PRIMARY : Button.colors.NEGATIVE}
                    onClick={() => {
                      setShowFilters((prevVal) => !prevVal);
                    }}
                  >
                    Add filter
                  </Button>
                )}
                */}
                <Button
                  size={Button.sizes?.MEDIUM}
                  kind={Button.kinds?.TERTIARY}
                  leftIcon={LearnMoreIcon}
                  onClick={() => {
                    //createSignal('help_modal');
                    setShowHelpModal(true);
                  }}
                >
                  Documentation
                </Button>
                <Button
                  leftIcon={UploadIcon}
                  size={Button.sizes?.MEDIUM}
                  kind={Button.kinds?.PRIMARY}
                  onClick={() => {
                    //createSignal('publish_widget_modal');
                    setShowPublishModal(true);
                  }}
                >
                  Publish
                </Button>
              </Flex>
            </Box>
          </Flex>
        </div>

        <div className="Container">
          <div className="Sidebar">
            <LayoutsSettings
              settings={settings}
              setSetting={setSetting}
              boardInfo={viewerData.boardInfo}
              setHelpState={setHelpState}
              settingCategory=""
              setShowHelpModal={setShowHelpModal}
            />

            {showFilters && board ? (
              <BoardRulesModal
                isOpen={showFilters}
                board={board}
                hideOrderBy={true}
                inititalBoardFilters={settings.boardFilters as any}
                onClose={(newBoardFilters) => {
                  if (newBoardFilters !== null && newBoardFilters !== undefined) {
                    setSetting('boardFilters', newBoardFilters);
                  }

                  setShowFilters(false);
                }}
              />
            ) : null}

            {ModeSettingsComponent ? (
              <ModeSettingsComponent
                settings={settings}
                setSetting={setSetting}
                boardInfo={viewerData.boardInfo}
                setHelpState={setHelpState}
                settingCategory=""
                setShowHelpModal={setShowHelpModal}
              />
            ) : null}

            <ColumnSettings
              settings={settings}
              setSetting={setSetting}
              boardInfo={viewerData.boardInfo}
              setHelpState={setHelpState}
              settingCategory=""
              setShowHelpModal={setShowHelpModal}
            />

            <div className="Teaser Teaser--Inverted">
              <h4>How to use</h4>
              <p>
                <small>Learn how to get started with Board to Website Widget and how to use the app.</small>
              </p>
              <Button
                size={Button.sizes?.SMALL}
                kind={Button.kinds?.TERTIARY}
                color={Button.colors?.PRIMARY}
                leftIcon={ExternalPageIcon}
                onClick={() => {
                  window.open('https://getgorilla.app/products/board-to-website-widget-for-monday-com/get-started/', '_blank');
                }}
              >
                Get started
              </Button>
            </div>
            <div className="Teaser Teaser--Inverted">
              <h4>Book a demo</h4>
              <p>
                <small>Let's answer questions, and review ways how we can help you and your organization.</small>
              </p>
              <Button
                size={Button.sizes?.SMALL}
                kind={Button.kinds?.TERTIARY}
                color={Button.colors?.PRIMARY}
                leftIcon={ExternalPageIcon}
                onClick={() => {
                  window.open('https://calendly.com/d/z5z-yb9-bxw/30min', '_blank');
                }}
              >
                Schedule demo
              </Button>
            </div>
            <div className="Teaser" style={{ paddingBottom: '30px' }}>
              <h4>
                Build Client Portals <br />
                for monday.com
              </h4>
              <div
                style={{
                  cursor: 'pointer',
                  margin: '20px  auto',
                  lineHeight: 0,
                }}
                onClick={() => {
                  window.open('https://getgorilla.app/products/client-portal', '_blank');
                }}
              >
                <img src="/client-portal-mobile-teaser.png" width="200" height="405" alt="" style={{ width: '90%', height: 'auto' }} />
              </div>
              <p style={{ marginBottom: '20px' }}>
                <small>
                  Optimized across all devices. <br />
                  Tailored to your brand and <br />
                  unique requirements.
                </small>
              </p>
              <Button
                size={Button.sizes?.MEDIUM}
                kind={Button.kinds?.PRIMARY}
                color={Button.colors?.ON_PRIMARY_COLOR}
                onClick={() => {
                  window.open('https://getgorilla.app/products/client-portal', '_blank');
                }}
              >
                Learn more
              </Button>
            </div>
            {/* 
            <Box padding={Box.paddings?.LARGE} paddingTop={Box.paddingTops?.XS} className="WidgetButtons">
              <Flex gap={10} direction={Flex.directions?.COLUMN}>
                <Tooltip content="Reset to default values" position={Tooltip.positions?.RIGHT} zIndex={2000}>
                  <Button
                    size={Button.sizes?.MEDIUM}
                    kind={Button.kinds?.SECONDARY}
                    color={Button.colors?.NEGATIVE}
                    onClick={() => {
                      //createSignal'reset_settings');
                      setShowResetModal(true);
                    }}
                    style={{
                      width: '100%',
                    }}
                  >
                    Reset settings
                  </Button>
                </Tooltip>
                <Tooltip content="Delete the published widget" position={Tooltip.positions?.RIGHT} zIndex={2000}>
                  <Button
                    disabled={!isPublished}
                    size={Button.sizes?.MEDIUM}
                    kind={Button.kinds?.PRIMARY}
                    color={Button.colors?.NEGATIVE}
                    onClick={() => {
                      //createSignal'delete_widget');
                      setShowDeleteModal(true);
                    }}
                    style={{
                      width: '100%',
                    }}
                  >
                    Delete widget
                  </Button>
                </Tooltip>
              </Flex>
            </Box>
*/}
          </div>

          <div className="Content">
            {widgetInfo?.id ? (
              <>
                <div className="FrameWrapper--Header">
                  <Tooltip content="Show widget preview" zIndex={2000}>
                    <div
                      className={editorTab === 'preview' ? 'FrameWrapper--Label FrameWrapper--LabelActive' : 'FrameWrapper--Label'}
                      onClick={() => {
                        setEditorTab('preview');
                      }}
                    >
                      <div>Preview</div>
                    </div>
                  </Tooltip>
                  {widgetInfo.publishedAt ? (
                    <Tooltip content="Show published widget" zIndex={2000}>
                      <div
                        className={editorTab === 'published' ? 'FrameWrapper--Label FrameWrapper--LabelActive' : 'FrameWrapper--Label'}
                        onClick={() => {
                          setEditorTab('published');
                        }}
                      >
                        <div>Published</div>
                      </div>
                    </Tooltip>
                  ) : null}
                  <div className="FrameWrapper--Header__Actions">
                    <Flex gap={12}>
                      {hasBoardFilters(board, settings.boardFilters as any) ? (
                        <Tooltip content="Edit board column rules" position={Tooltip.positions?.TOP} zIndex={2000}>
                          <Button
                            leftIcon={FilterIcon}
                            kind={Button.kinds?.TERTIARY}
                            size={Button.sizes?.SMALL}
                            color={
                              areBoardFiltersValid(board, settings.boardFilters as any) ? Button.colors.PRIMARY : Button.colors.NEGATIVE
                            }
                            onClick={() => {
                              setShowFilters((prevVal) => !prevVal);
                            }}
                          >
                            Edit filters
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Add board column rules" position={Tooltip.positions?.TOP} zIndex={2000}>
                          <Button
                            leftIcon={FilterIcon}
                            kind={Button.kinds?.TERTIARY}
                            size={Button.sizes?.SMALL}
                            color={
                              areBoardFiltersValid(board, settings.boardFilters as any) ? Button.colors.PRIMARY : Button.colors.NEGATIVE
                            }
                            onClick={() => {
                              setShowFilters((prevVal) => !prevVal);
                            }}
                          >
                            Add filters
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip content="Reset settings to default values" position={Tooltip.positions?.TOP} zIndex={2000}>
                        <Button
                          leftIcon={RetryIcon}
                          size={Button.sizes?.SMALL}
                          kind={Button.kinds?.TERTIARY}
                          color={Button.colors?.PRIMARY}
                          onClick={() => {
                            //createSignal'reset_settings');
                            setShowResetModal(true);
                          }}
                        >
                          Reset settings
                        </Button>
                      </Tooltip>
                      <Tooltip content="Unpublish the widget" position={Tooltip.positions?.TOP} zIndex={2000}>
                        <Button
                          disabled={!isPublished}
                          leftIcon={AlertIcon}
                          size={Button.sizes?.SMALL}
                          kind={Button.kinds?.TERTIARY}
                          color={Button.colors?.NEGATIVE}
                          onClick={() => {
                            //createSignal'delete_widget');
                            setShowDeleteModal(true);
                          }}
                        >
                          Unpublish
                        </Button>
                      </Tooltip>
                    </Flex>
                  </div>
                </div>
                <div className="FrameWrapper">
                  {editorTab === 'preview' ? (
                    <Frame
                      id="viewer-iframe"
                      initialContent='<!DOCTYPE html><html><head><link href="/fontawesome/css/fontawesome.min.css" rel="stylesheet"><link href="/fontawesome/css/regular.min.css" rel="stylesheet"></link><link href="/fontawesome/css/solid.min.css" rel="stylesheet"></link></head><body><div id="Content"></div></body></html>'
                      mountTarget="#Content"
                    >
                      <Viewer
                        boardInfo={viewerData.boardInfo}
                        boardItems={viewerData.items}
                        assets={viewerData.assets}
                        settings={settings}
                        mode="preview"
                        widgetId={widgetInfo.id}
                        styles={viewerStyles}
                        previewTumbnailGeneratorUrl={`${import.meta.env.VITE_PUBLISHER_ENDPOINT_URL}/preview-thumbnail`}
                      />
                    </Frame>
                  ) : (
                    <PublishedIframe src={`${import.meta.env.VITE_WIDGETS_VIEWER_URL}/${widgetInfo.id}`} />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Editor;
