import { useRef, useState, useEffect } from 'react';
import * as _ from 'lodash';
import { ViewerBoardInfo, ViewerBoardItem, ViewerBoardItemValue, ViewerAsset } from '@gorilla/widgets-viewer/app/viewer/types';
import { getBoardItems, getBoard, getAssets, Board } from '@gorilla/common/src/lib/monday-api/api';
import { boardItemMondayToBoardItemEngine, EngineBoardItem } from '@gorilla/common/src/lib/engine/engine';
import { boardToBoardInfo } from '@gorilla/widgets-shared/src/services/publish';
import { mondayClient } from '../services/monday';

function toViewerFormat(board: Board, itemsArg: EngineBoardItem[]): { boardInfo: ViewerBoardInfo; items: ViewerBoardItem[] } {
  const boardInfo = boardToBoardInfo(board);

  const items = itemsArg.map((item) => ({
    id: String(item.id),
    name: item.name,
    group: item.group,
    values: item.values.reduce(
      (acc, value) => {
        acc[value.column_id] = {
          id: value.column_id,
          value: value.value,
        };

        return acc;
      },
      {} as Record<string, ViewerBoardItemValue>,
    ),
  })) as any as ViewerBoardItem[];

  return { boardInfo, items };
}

export function useItemLoader(boardId?: number, boardFiltersGraphQL?: string) {
  const [status, setStatus] = useState('initializing');
  const [progress, setProgress] = useState<number | null>(null);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (!boardId) {
      return;
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setStatus('initializing');
    setProgress(null);

    (async () => {
      try {
        const board = await getBoard(mondayClient, boardId);

        if (!board) {
          throw new Error(`Failed to fetch board ${boardId}`);
        }

        const items = await getBoardItems(mondayClient, boardId, {
          abortSignal: abortController.signal,
          queryParams: boardFiltersGraphQL,
          onProgress: (progress) => {
            setStatus('fetching-items');
            setProgress(progress);
          },
        });
        const itemsEngine = items.map((item) => boardItemMondayToBoardItemEngine(board, item));

        if (!itemsEngine) {
          throw new Error(`Failed to fetch board items ${boardId}`);
        }

        const assetIds = _.uniq(
          _.flatten(
            itemsEngine.map((item) =>
              _.flatten(
                Object.values(item.values).flatMap((value) => {
                  if (value.column_type === 'file' && value.value_type === 'success') {
                    return value.value.asset_ids || [];
                  }

                  return [];
                }),
              ),
            ),
          ),
        );

        let assets: ViewerAsset[] = [];

        if (assetIds.length) {
          assets = ((await getAssets(mondayClient, assetIds)) || []).map((asset) => ({
            id: parseInt(asset.id, 10),
            name: asset.name,
            extension: asset.file_extension,
            size: asset.file_size,
            uploaded: true,
            public_url: asset.public_url,
          }));
        }

        const data = {
          ...toViewerFormat(board, itemsEngine),
          assets,
        };

        setData(data);
      } catch (err) {
        setError(err);
        setStatus('error');
        setProgress(null);
      }
    })();
  }, [boardId, boardFiltersGraphQL]);

  let loadingMessage = 'Loading board items';

  if (status === 'error') {
    loadingMessage = 'Loading failed';
  } else if (progress !== null) {
    loadingMessage = `Loading board items ${progress}%`;
  }

  return {
    error,
    status,
    progress,
    loadingMessage,
    data,
  };
}
